import React, {useState} from "react";
import "./ImportFileMessage.scss";

interface IImportFileMessageProps {
  handleFile: (file: File) => void;
  file: File | null;
}

/**
 * @name ImportFileMessage
 * @description Component to import a file in a message.
 * @constructor
 */
const ImportFileMessage: React.FC<IImportFileMessageProps> = (props: IImportFileMessageProps) => {
  const [error, setError] = useState<string | null>(null);

  const checkFileSize = (file: File): boolean => file.size < 4000000;

  const checkFileType = (file: File): boolean => {
    const type = file.type.split('/')[1];
    return (
      type === 'pdf' ||
      type === 'png' ||
      type === 'jpeg' ||
      type === 'jpg' ||
      type === 'mp4' ||
      type === 'webm' ||
      type === 'mp3' ||
      type === 'mpeg'
    );
  }

  const handleFile = (file: File): void => {
    if (file && checkFileSize(file) && checkFileType(file)) {
      props.handleFile(file);
      setError(null);
    } else {
      props.handleFile(null);
      setError('Le fichier est trop volumineux ou le format n\'est pas supporté.');
    }
  }

  const handleRemoveFile = (): void => {
    props.handleFile(null);
    setError(null);
  }

  const pdfPreview = (file: File): JSX.Element => {
    return (
      <iframe
        src={URL.createObjectURL(file) + "#view=fit"}
        title="pdf-preview"
        className="pdf-preview"
        frameBorder={0}
      ></iframe>
    );
  }

  const videoPreview = (file: File): JSX.Element => {
    return (
      <video
        src={URL.createObjectURL(file)}
        title="video-preview"
        className="video-preview"
        controls={true}
        onClick={() => {
          const video = document.getElementById("videoPreview") as HTMLVideoElement;
          if (video.paused) {
            video.play();
          } else {
            video.pause();
          }
        }}
      ></video>
    );
  }

  const imagePreview = (file: File): JSX.Element => {
    return (
      <img
        src={URL.createObjectURL(file)}
        alt="preview"
        onClick={() => {
          window.open(URL.createObjectURL(file));
        }}
      />
    );
  }

  const audioPreview = (file: File): JSX.Element => {
    return (
      <audio controls={true} id="audioPreview">
        <source src={URL.createObjectURL(file)} type={file.type}/>
      </audio>
    );
  }

  const previewFile = (file: File): JSX.Element => {
    const type: string = file.type.split("/")[1];

    if (type === "pdf") {
      return pdfPreview(file);
    } else if (type === "png" || type === "jpeg" || type === "jpg") {
      return imagePreview(file);
    } else if (type === "mp4" || type === "webm") {
      return videoPreview(file);
    } else if (type === "mp3" || type === "mpeg") {
      return audioPreview(file);
    } else {
      return <p>Format non supporté.</p>;
    }
  }

  return (
    <div className="import-file-messsage">
      {!props.file && (
        <>
          <label
            htmlFor="import-file-input"
            className="label-import-file">
            <p>Ajouter un fichier.</p>
            <span>Formats acceptés : pdf, png, jpeg, jpg, mp3, mp4, webm.</span>
            <span>Taille maximum: 100 Mo</span>
          </label>

          <input
            type="file"
            id="import-file-input"
            className="import-file-input"
            onChange={(e) => {
              if (e.target.files) {
                handleFile(e.target.files[0]);
              }
            }}
            name="file"
            accept="image/png, image/jpeg, image/jpg, application/pdf, audio/mpeg, audio/mp3, video/mp4, video/webm"
            max={100}
          />
        </>
      )}

      {props.file && (
        <div className="file-preview">
          {previewFile(props.file)}
          <button
            onClick={() => handleRemoveFile()}
            className="remove"
            type="button"
          >
            x
          </button>
        </div>
      )}
      {error != null && (
        <p className="error">{error}</p>
      )}
    </div>
  );
};
export default ImportFileMessage;

import { Grid } from '@mui/material';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import EventEmmiter from '../../../services/event';

const AttachFileContainer: React.FC<any> = (props) => {
  const [isZoomEffect, setIsZoomEffect] = React.useState<boolean>(false);
  const [attachment, setAttachment] = React.useState('');
  const [attachmentName, setAttachmentName] = React.useState('');

  React.useEffect(() => {
    setAttachment(props?.message?.attachment);
    setAttachmentName(props?.message?.attachment_name);
  }, [props?.message?.attachment])

  const isImage = () => {
    const str = attachmentName;
    const formats = ['png', 'jpg', 'jpeg', 'svg', 'ico', 'gif', 'apng'];
    if (!str || str?.length === 0) return (false);
    for (const format of formats) {
      if (str?.endsWith(format))
        return (true);
    }
    return (false);
  }

  const isPdf = () => {
    const str = attachmentName;
    const formats = ['pdf'];
    if (!str || str?.length === 0) return (false);
    for (const format of formats) {
      if (str?.endsWith(format))
        return (true);
    }
    return (false);
  }

  return (
    <Grid container direction='column' display='flex' wrap="nowrap">
      <Grid item display='grid'>
        <div className="text_champs">Ou joindre un fichier</div>
      </Grid>
      {attachment &&
        <Grid item display='grid' alignItems='center' justifyContent='center' paddingTop='15px'>
          <Grid container direction='column' wrap="nowrap" display='flex' border='2px dashed #8d9899' borderRadius='15px' padding='15px'>
            <Grid item display='grid'>
              <Grid container direction='row' justifyContent='space-between' alignItems='center' spacing='10px'>
                <Grid item display='grid' alignItems='center'>
                  <span
                    className='text_champs'>
                    {attachmentName}
                  </span>
                </Grid>
                <Grid item display='grid' alignItems='center'>
                  <button
                    onClick={() => {
                      const temp = { ...props?.tutorial };
                      if (props?.isEdit) {
                        delete temp.messages[0][props.index].attachment;
                        delete temp.messages[0][props.index].attachment_name;
                        EventEmmiter.emit("edit", {value: temp});
                      } else {
                        temp.messages[props.index].attachment = null;
                        temp.messages[props.index].attachment_name = null;
                        props?.setTutorial(temp);
                      }
                    }}
                    className={'delete_advice_button'}>
                    Supprimer
                  </button>
                </Grid>
              </Grid>
            </Grid>
            {isImage() &&
              <Grid item display='grid' alignItems='center' justifyContent='center'>
                <img className={isZoomEffect ? 'img-zoom-effect-loaded' : 'img-zoom-effect'}
                  onClick={() => setIsZoomEffect(!isZoomEffect)}
                  style={{ borderRadius: '20px', maxWidth: '330px' }}
                  src={attachment}
                  alt=''
                  key={attachment} />
              </Grid>
            }
            {isPdf() &&
              <Grid item display='grid' alignItems='center' justifyContent='center' style={{ resize: 'vertical', maxHeight: '1000px', minHeight: '300px', overflow: 'auto' }}>
                <iframe
                  style={{ borderRadius: '20px', minWidth: '500px', maxHeight: '1000px', minHeight: '300px', height: '100%', resize: 'vertical', overflow: 'hidden' }}
                  src={attachment} />
              </Grid>
            }
          </Grid>
        </Grid>
      }
      {!attachment &&
        <Grid item display='grid'>
          <Dropzone onDrop={props?.handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div className="file_modals" {...getRootProps()}>
                <div className="modal_file_button">
                  <div className="modal_file_button_text">
                    <input type="file" name="file" id="file" onChange={(e) => {
                      props?.handleFile(e, props?.index);
                    }}
                      className="modal_file_button_text"
                      placeholder="Joindre un fichier (image, vidéo ou document)" />
                    <label htmlFor="file">Joindre un fichier (image, vidéo ou document)</label>
                  </div>
                </div>
                <div className="accept_format">
                  Formats acceptés : pdf, png, jpeg, jpg, mp3, mp4, webm. Maxi 100mo.
                </div>
                {/* <p>Ou glisser / déposer ici le fichier</p> */}
                <br />
              </div>)}
          </Dropzone>
        </Grid>
      }
    </Grid>
  );
};

export default AttachFileContainer;
import { AiFillEye, AiOutlineDown } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";
import "../../../styles/ConseilCard.scss";
import { ReactComponent as IconSection } from "../../../assets/IconSection.svg";
import * as React from "react";
import SelectedAdvice from "../../../utils/SelectedAdvice";
import { Grid } from "@mui/material";
import ImportFileMessage from "./ImportFileMessage/ImportFileMessage";
import { useEffect } from "react";
import useAppSelector from "../../../redux/useAppSelector";

const AdviceToSendContainer: React.FC<any> = (props) => {
  const user = useAppSelector((x) => x?.userReducer?.user);
  return (
    <div
      style={{
        paddingTop: "13px",
        paddingLeft: "19px",
        paddingRight: "19px",
        paddingBottom: "5px",
      }}
    >
      <span className="conseil-send-title">
        <IconSection /> Conseils à envoyer
      </span>
      <div className="conseil-send-desc">Depuis la bibliothèque</div>
      <div
        className="conseil-send-drop border-animation"
        id="advice-to-send-box"
        style={{
          borderColor:
            props?.showError && props?.error === "NoAdviceSelectedAndNeeded"
              ? "#FF3100"
              : null,
        }}
      >
        <p>Pour ajouter un conseil, cliquer sur le +</p>
      </div>

      {props.showed ? (
        <div
          className="prev_card"
          style={{ top: `${640 + (props.index - 1) * 65}px`, left: "34%" }}
        >
          <h3>{props.acutalTutorial.title}</h3>
          <p>{props.acutalTutorial.messages[0][0].content}</p>
        </div>
      ) : null}
      <div className="conseil-send-desc"></div>
      <ul className="drop list-conseil">
        {props.freeText == false &&
          props.selectedAdvice &&
          props.selectedAdvice.map((n, i) => (
            <li key={i} className="Conseil-send-li">
              <div style={{ display: "flex", margin: "0" }}>
                {n.type == "pack" ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      color: "#5bb9c1",
                      font: "normal normal bold 12px/18px Poppins",
                      margin: "auto",
                      padding: "5px",
                    }}
                  >
                    pack
                  </div>
                ) : (
                  ""
                )}
                <div style={{ margin: "auto", marginLeft: "15px" }}>
                  {n.title}
                </div>
              </div>
              <div>
                <a
                  onClick={() => props.setActual(true, n, i)}
                  style={{ cursor: "pointer" }}
                >
                  <AiFillEye />
                </a>
                <a
                  onClick={() => {
                    props.handleDelete(n.id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <GiCancel />
                </a>
              </div>
            </li>
          ))}
      </ul>
      {user?.interface && !["8742"].includes(user?.interface) && (
        <div className="setFreeText">
          <p>Ou texte libre : </p>
          <p
            className="freeTextDisplay"
            onClick={() => {
              const selectedAdviceObject = new SelectedAdvice();

              if (!props.freeText) {
                selectedAdviceObject.clear();
                props.setSelectedAdvice(selectedAdviceObject.getAll());
              }
              props.setFreeText(!props.freeText);
            }}
          >
            Saisir un texte personnalisé <AiOutlineDown />{" "}
          </p>
        </div>
      )}
      {props.freeText ? (
        <div style={{ width: "100%" }}>
          <span className="last-char">
            {325 - props.inputText.length} caractères
          </span>
          <form
            name="form"
            action="/"
            method="GET"
            className="form"
            style={{ width: "100%" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <textarea
                  placeholder="Saisir ici un texte si vous le souhaitez"
                  className="input-conseil border-animation"
                  name="freeText"
                  id="free-text-input"
                  onChange={(e) => props.setInputText(e.target.value)}
                  style={{
                    borderColor:
                      props?.showError && props?.error === "NoFreeTextAndNeeded"
                        ? "#FF3100"
                        : null,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ImportFileMessage
                  handleFile={props.handleFile}
                  file={props.file}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default AdviceToSendContainer;

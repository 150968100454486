import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import Login from "./components/login";
import Register from "./components/register";
import AppComponent from "./components/AppComponent";
import AutoLog from "./components/AutoLog";
import GetProSanteConnect from "./components/GetProSanteConnect";
import Administration from "./components/Administration";
import ProfilePage from "./pages/ProfilePage";
import SendMessagesPage from "./pages/SendMessagesPage";
import ScheduledConseilsPage from "./pages/ScheduledConseilsPage";
import { Store } from "./redux/store";
import { Provider } from "react-redux";
import {
  updateWidthAndHeight,
} from "./redux/windowReducer";
import { closeSnackbar, openSnackbar } from "./redux/snackbarReducer";
import { useDispatch } from "react-redux";
import PatientListPage from "./pages/PatientListPage";
import CreateOrEditQuestionnairePage from "./pages/CreateOrEditQuestionnairePage";
import MySnackbar from "./components/Snackbars/MySnackbar";
import { updateSize } from "./redux/componentSizeReducer";
import QuestionnaireViewPage from "./pages/QuestionnaireViewPage";
import QuestionnaireReponsePage from "./pages/QuestionnaireReponsesPage";
import makeAPIRequest from "./utils/makeAPIRequest";
import {
  setChatbotsAccess,
  setChatbotsLexAccess,
  setFormsAccess,
  setProtocolesAccess,
} from "./redux/authorizationsReducer";
import forceDisconnectUser from "./utils/forceDisconnectUser";
import AdminUsersPage from "./pages/AdminUsersPage";
import AdminParametersPage from "./pages/AdminParametersPage";
import { updateTokensCost } from "./redux/tokensReducer";
import CreateOrEditChatbotPage from "./pages/CreateOrEditChatbotPage";
import VitaleInstallation from "./components/VitaleInstallation/VitaleInstallation";
import ChatbotReponsePage from "./pages/ChatbotReponsePage";
import QuestionnairesScheduledPage from "./pages/QuestionnairesScheduledPage";
import SwaggerDocumentationPage from "./pages/SwaggerDocumentationPage";
import AdminGroupsPage from "./pages/AdminGroupsPage";
import { updateAccounts } from "./redux/groupsTokensReducer";
import AdminAlertsPage from "./pages/AdminAlertsPage";
import AdminActivityPage from "./pages/AdminActivityPage";
import AdminConsommationPage from "./pages/AdminConsommationPage";
import AdminOthersStatisticsPage from "./pages/AdminOthersStatisticsPage";
import { setCertificate, setHasGroup } from "./redux/INSiReducer";
import AdminGestionIncidentsPage from "./pages/AdminGestionIncidentsPage";
import CreateOrEditChatbotLexPage from "./pages/CreateOrEditChatbotLexPage";
import {
  getLanguagesAndParameters,
  getNotifications,
} from "./redux/new/notificationsReducer";
import useAppDispatch from "./redux/useAppDispatch";
import { getLanguage } from "./redux/new/languageReducer";
import { getUser } from "./redux/new/userReducer";
import useAppSelector from "./redux/useAppSelector";
import { IUser } from "./utils/UserClass";
import CreateOrEditPDFQuestionnaire from "./components/Questionnaire/PDF/CreateOrEditPDFQuestionnaire";
import ConseilViewPage from "./pages/ConseilViewPage";
import ConseilQRCodePage from "./pages/ConseilQRCodePage";
import PatientQRCodePage from "./pages/PatientQRCodePage";
import { getQuestionnairesInteractifs } from "./redux/new/questionnaireInteractifReducer";
import ValidationPage from "./pages/ValidationPage";
import SupportPage from "./pages/SupportPage";
import LinksPage from "./pages/LinksPage";
import StatistiquesConseilsPage from "./pages/StatistiquesConseilsPage";
import ConseilValidationPage from "./pages/ConseilValidationPage";
import AdminChiffresClesPage from "./pages/AdminChiffresClesPage";
import ResetPasswordPage from "./pages/ResetPassword";
import SharePublicFormStatsPage from "./pages/SharePublicFormStatsPage";
import AdminFacturesPage from "./pages/AdminFacturesPage";
import FacturesPage from "./pages/FacturesPage";

const Snackbars = () => {
  const dispatch = useDispatch();
  const snackbarReducer = useAppSelector((state) => state.snackbarReducer);

  return (
    <>
      <MySnackbar
        isOpen={snackbarReducer?.isOpen}
        message={snackbarReducer?.message}
        duration={snackbarReducer?.duration}
        width={snackbarReducer?.width}
        type={snackbarReducer?.type as any}
        onClose={() => dispatch(closeSnackbar(null))}
      />
      <MySnackbar
        isOpen={snackbarReducer?.bottomRight?.isOpen}
        message={snackbarReducer?.bottomRight?.message}
        vertical="bottom"
        horizontal="right"
        width={snackbarReducer?.bottomRight?.width}
        duration={snackbarReducer?.bottomRight?.duration}
        type={snackbarReducer?.bottomRight?.type as any}
        onClose={() => dispatch(closeSnackbar("bottomRight"))}
      />
      <MySnackbar
        isOpen={snackbarReducer?.bottomCenter?.isOpen}
        message={snackbarReducer?.bottomCenter?.message}
        vertical="bottom"
        horizontal="center"
        width={snackbarReducer?.bottomCenter?.width}
        duration={snackbarReducer?.bottomCenter?.duration}
        type={snackbarReducer?.bottomCenter?.type as any}
        onClose={() => dispatch(closeSnackbar("bottomCenter"))}
      />
    </>
  );
};

export const useUser = () => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (!localStorage.getItem("token")) {
      return;
    }
    dispatch(getUser());
  }, [dispatch]);
};

const useLanguage = () => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getLanguage());
  }, [dispatch]);
};

const useNotifications = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getLanguagesAndParameters());
      dispatch(getNotifications());
    }
    const interval = setInterval(() => {
      if (localStorage.getItem("token")) {
        dispatch(getNotifications());
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [dispatch]);
};

const AppWithRedux = () => {
  const user: IUser = useAppSelector((x) => x.userReducer.user);
  const dispatch = useAppDispatch();

  useUser();
  useLanguage(); // specialities, professions, civilities, etc...
  useNotifications();

  React.useEffect(() => {
    if (
      window.location.href.includes("auto-log") ||
      !localStorage.getItem("token")
    ) {
      return;
    }
    makeAPIRequest("get", "/parameters/list", null, "v2")
      .then((res) => {
        const parameters = res?.data?.data?.parameters;
        dispatch(
          updateTokensCost({
            sendSmsCost: parseInt(
              parameters.find((p) => p?.type == "send_sms_tokens_cost")?.value
            ),
            displayMoreConseilsCost: parseInt(
              parameters.find((p) => p?.type == "display_more_conseils_cost")
                ?.value
            ),
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    if (
      window.location.href.includes("auto-log") ||
      !localStorage.getItem("token")
    ) {
      return;
    }
    if (user?.account_type === "3") {
      dispatch(
        openSnackbar({
          message: "Votre compte de test a expiré, vous allez être déconnecté",
          type: "error",
          duration: 3000,
        })
      );
      setTimeout(() => {
        forceDisconnectUser();
      }, 3000); // Disconnect expired account
    }
  }, [user]);

  React.useEffect(() => {
    if (
      window.location.href.includes("auto-log") ||
      !localStorage.getItem("token")
    ) {
      return;
    }
    makeAPIRequest("get", "/questions/access/info", null, "v3")
      .then((res) => {
        dispatch(setFormsAccess(res?.data?.access));
      })
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    if (
      window.location.href.includes("auto-log") ||
      !localStorage.getItem("token")
    ) {
      return;
    }
    makeAPIRequest("get", "/chatbot/access/info", null, "v3")
      .then((res) => {
        dispatch(setChatbotsAccess(res?.data?.access));
      })
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    if (
      window.location.href.includes("auto-log") ||
      !localStorage.getItem("token")
    ) {
      return;
    }
    makeAPIRequest("get", "/chatbot_lex/access/info", null, "v3")
      .then((res) => {
        dispatch(setChatbotsLexAccess(res?.data?.access));
      })
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    if (
      window.location.href.includes("auto-log") ||
      !localStorage.getItem("token")
    ) {
      return;
    }
    makeAPIRequest("get", "/protocole/access/info", null, "v3")
      .then((res) => {
        dispatch(setProtocolesAccess(res?.data?.access));
      })
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    if (
      window.location.href.includes("auto-log") ||
      !localStorage.getItem("token")
    ) {
      return;
    }
    makeAPIRequest("get", "/group/accounts", null, "v3")
      .then((res) => {
        if (res?.data) {
          dispatch(updateAccounts(res?.data));
        }
      })
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    if (
      window.location.href.includes("auto-log") ||
      !localStorage.getItem("token")
    ) {
      return;
    }
    makeAPIRequest("get", "/insi/get_my_certificate", null, "v3")
      .then((res) => {
        if (res?.data) {
          if (res?.data?.success === false && res?.data?.error === "no_group") {
            dispatch(setHasGroup(false));
          } else {
            dispatch(setCertificate(res?.data?.certificate));
            dispatch(setHasGroup(true));
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    if (
      window.location.href.includes("auto-log") ||
      !localStorage.getItem("token")
    ) {
      return;
    }
    dispatch(getQuestionnairesInteractifs());
  }, [dispatch]);

  React.useEffect(() => {
    let resizeTimeout;

    function handleWindowResize() {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        if (!window.location.href.endsWith("/#/home") && !window.location.href.endsWith('/#/home?f=conseils')) {
          dispatch(updateSize("#mes-chatbots-box"));
          dispatch(updateSize("#mes-questionnaires-box"));
          dispatch(updateSize("#mes-chatbots-lex-box"));
          dispatch(updateSize("#admin-groups-box"));
          dispatch(updateSize("#admin-alerts-box"));
        }
        dispatch(
          updateWidthAndHeight({
            height: window.innerHeight,
            width: window.innerWidth,
          })
        );
      }, 50);
    }

    if (!window.location.href.endsWith("/#/home") && !window.location.href.endsWith('/#/home?f=conseils')) {
      dispatch(updateSize("#mes-chatbots-box"));
      dispatch(updateSize("#mes-questionnaires-box"));
      dispatch(updateSize("#mes-chatbots-lex-box"));
      dispatch(updateSize("#admin-groups-box"));
      dispatch(updateSize("#admin-alerts-box"));
    }
    dispatch(
      updateWidthAndHeight({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    );

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  /*
  React.useEffect(() => {
    function handleWindowResize() {
      console.log('ENTER RESIZE')
      dispatch(updateSize("#mes-chatbots-box"));
      dispatch(updateSize("#mes-chatbots-lex-box"));
      dispatch(updateSize("#mes-questionnaires-box"));
      dispatch(updateSize("#admin-groups-box"));
      dispatch(updateSize("#admin-alerts-box"));
      dispatch(updateWidthAndHeight({
        heigth: window?.innerHeight,
        width: window?.innerWidth,
      }))
    }
    dispatch(updateSize("#mes-chatbots-box"));
    dispatch(updateSize("#mes-questionnaires-box"));
    dispatch(updateSize("#mes-chatbots-lex-box"));
    dispatch(updateSize("#admin-groups-box"));
    dispatch(updateSize("#admin-alerts-box"));
    dispatch(updateWidthAndHeight({
      heigth: window?.innerHeight,
      width: window?.innerWidth,
    }))
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  */

  React.useEffect(() => {
    localStorage.removeItem("adviceSelected");
  }, []);

  return (
    <>
      <VitaleInstallation />
      <Snackbars />
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <PrivateRoute path="/home" component={AppComponent} />
          <PrivateRoute path="/support" component={SupportPage} />
          <PrivateRoute path="/profile" component={ProfilePage} user={user} />
          <PrivateRoute
            path="/send_messages"
            component={SendMessagesPage}
            user={user}
          />
          <PrivateRoute
            path="/scheduled_conseils"
            component={ScheduledConseilsPage}
            user={user}
          />
          <PrivateRoute
            path="/links"
            component={LinksPage}
            user={user}
          />
          <PrivateRoute
            path="/factures"
            component={FacturesPage}
            user={user}
          />
          <PrivateRoute
            path="/statistiques_conseils"
            component={StatistiquesConseilsPage}
            user={user}
          />
          <PrivateRoute
            path="/admin_users"
            component={AdminUsersPage}
            user={user}
          />
          <PrivateRoute
            path="/admin_groups"
            component={AdminGroupsPage}
            user={user}
          />
          <PrivateRoute
            path="/admin_alerts"
            component={AdminAlertsPage}
            user={user}
          />
          <PrivateRoute
            path="/admin_parameters"
            component={AdminParametersPage}
            user={user}
          />
          <PrivateRoute
            path="/admin_consommation"
            component={AdminConsommationPage}
            user={user}
          />
          <PrivateRoute
            path="/admin_gestion_incidents"
            component={AdminGestionIncidentsPage}
            user={user}
          />
          <PrivateRoute
            path="/admin_activity_history"
            component={AdminActivityPage}
            user={user}
          />
          <PrivateRoute
            path="/admin_others_statistics"
            component={AdminOthersStatisticsPage}
            user={user}
          />
          <PrivateRoute
            path="/chiffres_cles"
            component={AdminChiffresClesPage}
            user={user}
          />
          <PrivateRoute
            path="/admin_factures"
            component={AdminFacturesPage}
            user={user}
          />
          <PrivateRoute
            path="/create_questionnaire_interactif"
            component={CreateOrEditChatbotPage}
            isCreate={true}
            isEdit={false}
          />
          <PrivateRoute
            path="/create_chatbot"
            component={CreateOrEditChatbotLexPage}
            isCreate={true}
            isEdit={false}
          />
          <PrivateRoute
            path="/edit_chatbot/:id"
            component={CreateOrEditChatbotLexPage}
            isCreate={false}
            isEdit={true}
          />
          <PrivateRoute
            path="/edit_questionnaire_interactif/:id"
            component={CreateOrEditChatbotPage}
            isCreate={false}
            isEdit={true}
          />
          <PrivateRoute
            path="/create_pdf_questionnaire"
            component={CreateOrEditPDFQuestionnaire}
            isCreate={true}
            isEdit={false}
          />
          <PrivateRoute
            path="/edit_pdf_questionnaire/:id"
            component={CreateOrEditPDFQuestionnaire}
            isCreate={false}
            isEdit={true}
          />
          <PrivateRoute
            path="/create_questionnaire"
            component={CreateOrEditQuestionnairePage}
            isCreate={true}
            isEdit={false}
          />
          <PrivateRoute
            path="/edit_questionnaire/:id"
            component={CreateOrEditQuestionnairePage}
            isCreate={false}
            isEdit={true}
          />
          <PrivateRoute path="/admin" component={Administration} />
          <PrivateRoute
            path="/questionnaire/:id/reponses"
            component={QuestionnaireReponsePage}
          />
          <PrivateRoute
            path="/questionnaires-scheduled"
            component={QuestionnairesScheduledPage}
          />
          <PrivateRoute
            path="/chatbot/:id/reponses"
            component={ChatbotReponsePage}
          />
          <PrivateRoute path="/conseil_qrcode" component={ConseilQRCodePage} />
          <Route
            exact
            path="/documentation"
            component={SwaggerDocumentationPage}
          />
          <Route exact path="/q/:id" component={QuestionnaireViewPage} />
          <Route exact path="/validation" component={ValidationPage} />
          <Route exact path="/reset_password" component={ResetPasswordPage} />
          <Route exact path="/conseil_validation" component={ConseilValidationPage} />
          <Route exact path="/c/:id" component={ConseilViewPage} />
          <Route exact path="/qstats/:id" component={SharePublicFormStatsPage} />
          <Route exact path="/qr/:id" component={PatientQRCodePage} />
          <Route exact path="/patient_list_v2" component={PatientListPage} />
          <Route exact path="/auto-log:slug" component={AutoLog} />
          <Route exact path="/psc/:slug" component={GetProSanteConnect} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
        </Switch>
      </Router>
    </>
  );
};

const App: React.FC = () => {
  return (
    <Provider store={Store}>
      <AppWithRedux />
    </Provider>
  );
};

export default App;

import {
  ClearRounded,
  Delete,
  Edit,
  EditRounded,
  SettingsRounded,
} from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";
import { Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FullField from "../Fields/FullField";
import TextInput from "../Library/Inputs/TextInput";
import {
  GenericFromPopup,
  GenericSendButton,
} from "../../pages/ChatbotLexPage";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import {
  IChatbot,
  IConditionChatbot,
  IQuestionChatbot,
} from "./initObjects/initChatbot";
import AddButton from "../Library/Buttons/AddButton/AddButton";
import { GenericSelect } from "../../pages/QuestionnairePage";
import { nanoid } from "nanoid";
import {
  getCondTypes,
  translateCondType,
} from "../Questionnaire/EditQuestionnaire/Fields/DisplayConfirmMessage";
import {
  GenericLongText,
  GenericTextInput,
} from "../../pages/CreateOrEditChatbotLexPage";
import CancellablePopup from "../Library/Popups/CancellablePopup";
import NewConditions from "../NewConditions";
import { ButtonIconWithTooltip } from "../Questionnaire/PDF/CreateOrEditPDFQuestionnaire";
import isPositiveNumber from "../../utils/isPositiveNumber";
import { GenericHourInput } from "../Questionnaire/GenerateQuestionnaire/Fields/DisplayDate";

const getActionsOptions = () => {
  return [
    {
      value: "send_sms",
      label: "Envoyer un SMS",
    },
    {
      value: "send_alert_by_email",
      label: "Recevoir une alerte par e-mail",
    },
    {
      value: "add_question",
      label: "Envoyer une question (obsolète, ne pas utiliser)",
    },
  ];
};

const translateAction = (type: string) => {
  const converter = {
    add_question: "Envoyer une question (obsolète, ne pas utiliser)",
    send_sms: "Envoyer un SMS",
    send_alert_by_email: "Recevoir une alerte par e-mail",
  };
  if (type in converter) {
    return converter[type];
  }
  return type;
};

const EditResponseConditionsOpen: React.FC<{
  chatbotData: IChatbot;
  openParameters: IQuestionChatbot;
  onOpenParametersUpdate: (newOpenParameters: IQuestionChatbot) => void;
  setOpenEditConditionsResponseOpen: any;
}> = ({
  chatbotData,
  openParameters,
  onOpenParametersUpdate,
  setOpenEditConditionsResponseOpen,
}) => {
  const [result, setResult] = React.useState<IQuestionChatbot>(undefined);
  const noChangeInParameters =
    openParameters && chatbotData ? _.isEqual(openParameters, result) : true;

  React.useEffect(() => {
    if (openParameters) {
      setResult(openParameters);
    }
  }, [openParameters]);

  if (!result) {
    return <></>;
  }
  return (
    <GenericFromPopup
      onClose={() => setOpenEditConditionsResponseOpen(false)}
      title={{
        leftPart: "Modifier",
        rightPart: "les conditions de validation",
      }}
      addCloseSecurity={noChangeInParameters ? false : true}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap="15px"
        padding="20px"
      >
        <Grid item display="grid" width={"450px"}>
          <GenericLongText
            maxLength={325}
            fieldTitle="SMS à envoyer"
            id="send_sms_bad_response"
            placeholder="SMS à envoyer tant que la réponse de l'utilisateur est incorrecte"
            onChange={(e) => {
              setResult((x) => {
                return {
                  ...x,
                  validationText: e,
                };
              });
            }}
            value={result?.validationText ? result.validationText : ""}
          />
        </Grid>
        <Grid item display="grid">
          <NewConditions
            initialData={result?.validationConditions}
            onDataUpdate={(newData) => {
              setResult((x) => {
                return { ...x, validationConditions: newData };
              });
            }}
            options={(() => {
              if (chatbotData?.questions?.length == 0) {
                return [];
              }
              const index = chatbotData.questions.findIndex(
                (x) => x?.id === result?.id
              );
              if (index < 0) {
                return [];
              }

              chatbotData?.questions?.length > 0
                ? chatbotData.questions.map((x) => x?.text)
                : [];

              return chatbotData.questions
                .map((x) => {
                  return { id: x?.id, text: x?.text };
                })
                .slice(0, index + 1);
            })()}
          />
        </Grid>
        <Grid item display="grid">
          <GenericSendButton
            errorMessages={{
              noChange: "Veuillez modifier les paramètres avant de valider",
              noValidationText: "Le SMS à envoyer n'est pas renseigné",
            }}
            errorSelector={{
              noValidationText: "#send_sms_bad_response",
            }}
            invalidConditions={{
              noChange: noChangeInParameters,
              noValidationText: !result?.validationText,
            }}
            textButton="Mettre à jour"
            onSendClick={() => {
              onOpenParametersUpdate(result);
            }}
          />
        </Grid>
      </Grid>
    </GenericFromPopup>
  );
};

const EditSendConditionsOpen: React.FC<{
  chatbotData: IChatbot;
  openParameters: IQuestionChatbot;
  onOpenParametersUpdate: (newOpenParameters: IQuestionChatbot) => void;
  setOpenEditConditionsSendOpen: any;
}> = ({
  chatbotData,
  openParameters,
  onOpenParametersUpdate,
  setOpenEditConditionsSendOpen,
}) => {
  const [result, setResult] = React.useState<IQuestionChatbot>(undefined);

  const noChangeInParameters =
    openParameters && chatbotData ? _.isEqual(openParameters, result) : true;

  React.useEffect(() => {
    if (openParameters) {
      setResult(openParameters);
    }
  }, [openParameters]);

  if (!result) {
    return <></>;
  }
  return (
    <GenericFromPopup
      onClose={() => setOpenEditConditionsSendOpen(false)}
      title={{
        leftPart: "Modifier",
        rightPart: "les conditions d'envoi",
      }}
      addCloseSecurity={noChangeInParameters ? false : true}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap="10px"
        padding="20px"
      >
        <Grid item display="grid">
          <NewConditions
            initialData={result?.displayConditions}
            onDataUpdate={(newData) => {
              setResult((x) => {
                return { ...x, displayConditions: newData };
              });
            }}
            options={(() => {
              if (chatbotData?.questions?.length == 0) {
                return [];
              }
              const index = chatbotData.questions.findIndex(
                (x) => x?.id === result?.id
              );
              if (index <= 0) {
                return [];
              }

              chatbotData?.questions?.length > 0
                ? chatbotData.questions
                    .filter((x) => x?.id != result?.id)
                    .map((x) => x?.text)
                : [];

              return chatbotData.questions
                .map((x) => {
                  return { id: x?.id, text: x?.text };
                })
                .slice(0, index);
            })()}
          />
        </Grid>
        <Grid item display="grid">
          <GenericSendButton
            errorMessages={{
              noChange: "Veuillez modifier les paramètres avant de valider",
            }}
            errorSelector={{}}
            invalidConditions={{
              noChange: noChangeInParameters,
            }}
            textButton="Mettre à jour"
            onSendClick={() => {
              onOpenParametersUpdate(result);
            }}
          />
        </Grid>
      </Grid>
    </GenericFromPopup>
  );
};

const DisplayQuestions: React.FC<{
  chatbotData: IChatbot;
  setChatbotData: React.Dispatch<React.SetStateAction<IChatbot>>;
}> = (props) => {
  const [openParameters, setOpenParameters] =
    React.useState<IQuestionChatbot>(undefined);
  const [openEditConditionsSendOpen, setOpenEditConditionsSendOpen] =
    React.useState<boolean>(false);
  const [openEditConditionsResponseOpen, setOpenEditConditionsResponseOpen] =
    React.useState<boolean>(false);
  const { chatbotData, setChatbotData } = props;
  const [openAddAction, setOpenAddAction] =
    React.useState<IConditionChatbot>(undefined);
  const [confirmCloseParameters, setConfirmCloseParameters] =
    React.useState<boolean>(false);
  const [openEditCondition, setOpenEditAction] =
    React.useState<IConditionChatbot>(undefined);
  const toUseConditionSetter = openAddAction
    ? setOpenAddAction
    : setOpenEditAction;
  const toUseCondition = openAddAction ? openAddAction : openEditCondition;
  const noChangeInParameters =
    openParameters && chatbotData
      ? _.isEqual(
          openParameters,
          chatbotData?.questions?.find((y) => y.id == openParameters.id)
        )
      : true;

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedQuestions = Array.from(chatbotData.questions);
    const [movedQuestion] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, movedQuestion);

    setChatbotData({
      ...chatbotData,
      questions: reorderedQuestions,
    });
  };

  return (
    <>
      {openEditConditionsSendOpen && (
        <EditSendConditionsOpen
          onOpenParametersUpdate={(newParametersOpen) => {
            setOpenParameters(newParametersOpen);
            setOpenEditConditionsSendOpen(false);
          }}
          setOpenEditConditionsSendOpen={setOpenEditConditionsSendOpen}
          chatbotData={chatbotData}
          openParameters={openParameters}
        />
      )}
      {openEditConditionsResponseOpen && (
        <EditResponseConditionsOpen
          onOpenParametersUpdate={(newParametersOpen) => {
            setOpenParameters(newParametersOpen);
            setOpenEditConditionsResponseOpen(false);
          }}
          setOpenEditConditionsResponseOpen={setOpenEditConditionsResponseOpen}
          chatbotData={chatbotData}
          openParameters={openParameters}
        />
      )}
      {confirmCloseParameters && (
        <CancellablePopup
          firstTitle="Quitter"
          secondTitle="les paramètres ?"
          message="Voulez-vous vraiment fermer cette fenêtre et annuler vos modifications ?"
          confirmButtonText="Oui"
          cancelButtonText="Non"
          onConfirm={() => {
            setOpenParameters(undefined);
            setConfirmCloseParameters(false);
          }}
          onClose={() => setConfirmCloseParameters(false)}
        />
      )}
      {(openAddAction || openEditCondition) && (
        <GenericFromPopup
          subtitle={openParameters?.text ? openParameters.text : ""}
          title={{
            leftPart: openEditCondition ? "Modifier" : "Ajouter",
            rightPart: "une action",
          }}
          onClose={() => {
            setOpenAddAction(undefined);
            setOpenEditAction(undefined);
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            padding="20px"
            gap="20px"
          >
            <Grid item display="grid">
              <Grid item display="grid" xs minWidth="200px">
                <GenericTextInput
                  value={toUseCondition.title}
                  fieldTitleInPlaceholderOnly={false}
                  placeholder="Aucun titre"
                  fieldTitle="Titre de l'action"
                  id="title-question-chatbot"
                  onChange={(e) => {
                    toUseConditionSetter((x) => {
                      return { ...x, title: e?.target?.value };
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item display="grid">
              <FullField isMandatory={true} title="Condition à respecter">
                <Grid container direction="row" gap="10px">
                  <Grid item display="grid" minWidth="200px">
                    <GenericSelect
                      isClearable={false}
                      value={{
                        value: toUseCondition.type,
                        label: translateCondType(toUseCondition.type),
                      }}
                      options={getCondTypes()}
                      maxMenuHeight="120px"
                      fieldTitle=""
                      onChange={(e) => {
                        toUseConditionSetter((x) => {
                          return { ...x, type: e?.value };
                        });
                      }}
                      id="conditions-question-chatbot"
                      placeholder=""
                    />
                  </Grid>
                  <Grid
                    item
                    display="grid"
                    xs
                    marginTop="-2px"
                    minWidth="200px"
                  >
                    <GenericTextInput
                      value={toUseCondition.value}
                      fieldTitleInPlaceholderOnly={true}
                      fieldTitle="Valeur"
                      id="value-question-chatbot"
                      onChange={(e) => {
                        toUseConditionSetter((x) => {
                          return { ...x, value: e?.target?.value };
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </FullField>
            </Grid>
            <Grid item display="grid" minWidth="200px">
              <GenericSelect
                isMandatory={true}
                isClearable={false}
                value={{
                  value: toUseCondition.action,
                  label: translateAction(toUseCondition.action),
                }}
                options={getActionsOptions()}
                maxMenuHeight="120px"
                fieldTitle="Action à effectuer"
                onChange={(e) => {
                  toUseConditionSetter((x) => {
                    return { ...x, action: e?.value };
                  });
                }}
                id="actions-question-chatbot"
                placeholder=""
              />
            </Grid>

            {toUseCondition.action === "send_sms" && (
              <>
                <Grid item display="grid">
                  <GenericLongText
                    maxLength={325}
                    fieldTitle="SMS à envoyer"
                    id="send_sms_action_content"
                    onChange={(e) => {
                      toUseConditionSetter((x) => {
                        return {
                          ...x,
                          smsToSend: e,
                        };
                      });
                    }}
                    value={
                      toUseCondition?.smsToSend ? toUseCondition?.smsToSend : ""
                    }
                  />
                </Grid>
              </>
            )}
            {toUseCondition.action === "add_question" && (
              <>
                <Grid item display="grid">
                  <FullField isMandatory={true} title={"Question à envoyer"}>
                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      gap="10px"
                      alignItems={"center"}
                    >
                      <Grid item display="grid" xs minWidth="200px">
                        <GenericTextInput
                          value={
                            toUseCondition?.questionToAdd?.text
                              ? toUseCondition?.questionToAdd?.text
                              : ""
                          }
                          fieldTitle=""
                          id="send-question-text-chatbot"
                          onChange={(e) => {
                            toUseConditionSetter((x) => {
                              return {
                                ...x,
                                questionToAdd: x.questionToAdd
                                  ? {
                                      ...x.questionToAdd,
                                      text: e?.target?.value,
                                    }
                                  : {
                                      text: e?.target?.value,
                                      id: nanoid(),
                                      conditions: [],
                                      sendMailAfterResponse: false,
                                    },
                              };
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </FullField>
                </Grid>
                <Grid item display="grid">
                  <OrangeCheckBox
                    textStyle={{ fontSize: "14px" }}
                    onClick={() =>
                      toUseConditionSetter((x) => {
                        if (x.questionToAdd) {
                          return {
                            ...x,
                            questionToAdd: {
                              ...x.questionToAdd,
                              sendMailAfterResponse: x?.questionToAdd
                                ?.sendMailAfterResponse
                                ? false
                                : true,
                            },
                          };
                        }
                        return x;
                      })
                    }
                    value={
                      toUseCondition?.questionToAdd?.sendMailAfterResponse
                        ? true
                        : false
                    }
                    text="Recevoir un e-mail après chaque réponse à cette question"
                  />
                </Grid>
              </>
            )}
            <Grid item display="grid">
              <GenericSendButton
                onSendClick={() => {
                  setOpenParameters((y) => {
                    if (openAddAction) {
                      return {
                        ...y,
                        conditions: y?.conditions
                          ? [...y.conditions, toUseCondition]
                          : [toUseCondition],
                      };
                    }
                    return {
                      ...y,
                      conditions: y?.conditions
                        ? y.conditions.map((z) => {
                            if (z.id === openEditCondition.id) {
                              return openEditCondition;
                            }
                            return z;
                          })
                        : [],
                    };
                  });
                  setOpenAddAction(undefined);
                  setOpenEditAction(undefined);
                }}
                errorMessages={{
                  emptyValue: "La valeur n'est pas renseignée.",
                  emptyQuestionToAdd:
                    "La question à envoyer n'est pas renseignée.",
                  emptySmsToSend: "Le contenu du SMS n'est pas renseigné.",
                }}
                errorSelector={{
                  emptyValue: "#value-question-chatbot",
                  emptyQuestionToAdd: "#send-question-text-chatbot",
                  emptySmsToSend: "#send_sms_action_content",
                }}
                invalidConditions={{
                  emptyValue: !toUseCondition.value,
                  emptyQuestionToAdd:
                    toUseCondition.action === "add_question" &&
                    !toUseCondition?.questionToAdd?.text,
                  emptySmsToSend:
                    toUseCondition.action === "send_sms" &&
                    !toUseCondition?.smsToSend,
                }}
                isLoading={false}
                textButton={openAddAction ? "Ajouter" : "Modifier"}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      {openParameters && !confirmCloseParameters && (
        <GenericFromPopup
          subtitle={openParameters?.text ? openParameters.text : ""}
          title={{
            leftPart: "Paramètrer",
            rightPart: "ma question",
          }}
          onClose={() => {
            if (noChangeInParameters) {
              setOpenParameters(undefined);
            } else {
              setConfirmCloseParameters(true);
            }
          }}
        >
          <Grid
            container
            direction="column"
            wrap="nowrap"
            padding="20px"
            gap="25px"
          >
            <Grid item display="grid">
              <Grid container direction="column" wrap="nowrap" gap="15px">
                <Grid item display="grid">
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    gap="10px"
                    alignItems={"center"}
                  >
                    <Grid item display="grid">
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#0B243C",
                          fontFamily: "Poppins",
                        }}
                      >
                        Actions
                      </span>
                    </Grid>
                    <Grid item display="grid">
                      <AddButton
                        displayText={false}
                        addId={"button-add-condition"}
                        displayIconCreate={true}
                        onClick={() => {
                          setOpenAddAction({
                            action: "add_question",
                            id: nanoid(),
                            type: "=",
                            value: "",
                            questionToAdd: {
                              id: nanoid(),
                              text: "",
                              sendMailAfterResponse: false,
                            },
                            title: "",
                          });
                        }}
                        smallVersion={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!openParameters?.conditions ||
                openParameters?.conditions?.length == 0 ? (
                  <Grid item display="grid">
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#0B243C",
                        fontFamily: "Poppins",
                      }}
                    >
                      {`Aucune action n'a été créée pour cette question.`}
                    </span>
                  </Grid>
                ) : (
                  <Grid item display="grid">
                    <Grid container direction="row" gap="10px">
                      {openParameters.conditions.map((condition, index) => {
                        return (
                          <Grid item display="grid" key={index}>
                            <Grid
                              container
                              direction="row"
                              wrap="nowrap"
                              style={{
                                border: "1px solid rgba(11, 36, 60)",
                                backgroundColor: "rgba(247, 252, 252, 1)",
                                boxShadow:
                                  "3px 3px 2px 1px rgba(11, 36, 60, .3)",
                              }}
                              paddingLeft="7px"
                              paddingRight="7px"
                              borderRadius="10px"
                            >
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                                alignItems="center"
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                  {condition.title
                                    ? condition.title
                                    : "Sans titre"}
                                </span>
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    borderLeft: "1px solid #0B243C",
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid" alignItems="center">
                                <Edit
                                  style={{
                                    color: "#0B243C",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setOpenEditAction(condition);
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                paddingLeft="5px"
                                paddingRight="5px"
                              >
                                <div
                                  style={{
                                    height: "100%",
                                    borderLeft: "1px solid #0B243C",
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid" alignItems="center">
                                <Delete
                                  style={{
                                    color: "#0B243C",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setOpenParameters((y) => {
                                      return {
                                        ...y,
                                        conditions: y.conditions.filter(
                                          (z) => z?.id !== condition.id
                                        ),
                                      };
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item display="grid">
              <Grid container direction="column" wrap="nowrap" gap="10px">
                <Grid item display="grid">
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#0B243C",
                      fontFamily: "Poppins",
                    }}
                  >
                    Options
                  </span>
                </Grid>
                <Grid item display="grid">
                  <Grid container direction="column" wrap="nowrap" gap="10px">
                    <Grid item display="grid">
                      <OrangeCheckBox
                        textStyle={{ fontSize: "14px" }}
                        onClick={() =>
                          setOpenParameters((x) => {
                            return {
                              ...x,
                              sendMailAfterResponse: x?.sendMailAfterResponse
                                ? false
                                : true,
                            };
                          })
                        }
                        value={
                          openParameters?.sendMailAfterResponse ? true : false
                        }
                        text="Recevoir un e-mail après chaque réponse à cette question"
                      />
                    </Grid>
                    <Grid item display="grid">
                      <OrangeCheckBox
                        textStyle={{ fontSize: "14px" }}
                        onClick={() =>
                          setOpenParameters((x) => {
                            return {
                              ...x,
                              sendEveryDay: {
                                ...x.sendEveryDay,
                                isActive: x?.sendEveryDay?.isActive
                                  ? false
                                  : true,
                              },
                            };
                          })
                        }
                        value={
                          openParameters?.sendEveryDay?.isActive ? true : false
                        }
                        text="Programmer l'envoi de cette question tous les jours à une heure précise"
                      />
                    </Grid>
                    <Grid item display="grid">
                      <OrangeCheckBox
                        textStyle={{ fontSize: "14px" }}
                        onClick={() =>
                          setOpenParameters((x) => {
                            return {
                              ...x,
                              isDDMMYYYY: x?.isDDMMYYYY ? false : true,
                            };
                          })
                        }
                        value={openParameters?.isDDMMYYYY ? true : false}
                        text="La réponse attendue doit contenir une date (DD/MM/YYYY)"
                      />
                    </Grid>
                    {openParameters?.isDDMMYYYY && (
                      <GenericLongText
                        maxLength={325}
                        fieldTitle="SMS à envoyer"
                        id="send_sms_bad_date_response"
                        placeholder="Veuillez réécrire la date sous le bon format : DD/MM/YYYY."
                        onChange={(e) => {
                          setOpenParameters((x) => {
                            return {
                              ...x,
                              badDateSMS: e,
                            };
                          });
                        }}
                        value={
                          openParameters?.badDateSMS ? openParameters.badDateSMS : ""
                        }
                      />
                    )}
                    {openParameters?.sendEveryDay?.isActive && (
                      <Grid item display="grid">
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing="10px"
                          style={{
                            fontFamily: "Poppins",
                            letterSpacing: "0px",
                            fontSize: "14px",
                            color: "#0B243C",
                          }}
                        >
                          <Grid item display="grid">
                            <span>Tous les jours à</span>
                          </Grid>
                          <Grid item display="grid">
                            <GenericHourInput
                              fieldTitle=""
                              id="chatbot-hour-input"
                              value={openParameters?.sendEveryDay?.hour}
                              onChange={(e) => {
                                setOpenParameters((x) => {
                                  return {
                                    ...x,
                                    sendEveryDay: {
                                      ...x.sendEveryDay,
                                      hour: e.target?.value,
                                    },
                                  };
                                });
                              }}
                            />
                          </Grid>
                          <Grid item display="grid">
                            <span
                              style={{
                                fontFamily: "Poppins",
                                letterSpacing: "0px",
                                fontSize: "16px",
                                color: "#0B243C",
                              }}
                            >
                              pendant
                            </span>
                          </Grid>
                          <Grid item display="grid">
                            <div style={{ maxWidth: "65px" }}>
                              <GenericTextInput
                                fieldTitle=""
                                id="duration-chatbot-input"
                                value={openParameters?.sendEveryDay?.duration}
                                onChange={(e) => {
                                  if (
                                    e?.target?.value?.length > 0 &&
                                    !isPositiveNumber(e.target.value)
                                  ) {
                                    return;
                                  }
                                  setOpenParameters((x) => {
                                    return {
                                      ...x,
                                      sendEveryDay: {
                                        ...x.sendEveryDay,
                                        duration: e.target?.value,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item display="grid">
                            <span
                              style={{
                                fontFamily: "Poppins",
                                letterSpacing: "0px",
                                fontSize: "16px",
                                color: "#0B243C",
                              }}
                            >
                              jour(s)
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                wrap="nowrap"
                gap="10px"
                alignItems={"center"}
              >
                <Grid item display="grid">
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      color: "#0B243C",
                      fontFamily: "Poppins",
                    }}
                  >
                    Conditions de validation de réponse
                  </span>
                </Grid>
                <Grid item display="grid">
                  <ButtonIconWithTooltip
                    Icon={
                      <EditRounded
                        fontSize="small"
                        style={{ color: "#0B243C" }}
                      />
                    }
                    onClick={() => {
                      setOpenEditConditionsResponseOpen(true);
                    }}
                    tooltipText="Modifier"
                  />
                </Grid>
              </Grid>
            </Grid>
            {chatbotData?.questions?.findIndex(
              (x) => x?.id === openParameters?.id
            ) !== 0 && (
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap" gap="10px">
                  <Grid item display="grid">
                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      gap="10px"
                      alignItems={"center"}
                    >
                      <Grid item display="grid">
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "#0B243C",
                            fontFamily: "Poppins",
                          }}
                        >
                          Conditions d'envoi
                        </span>
                      </Grid>
                      <Grid item display="grid">
                        <ButtonIconWithTooltip
                          Icon={
                            <EditRounded
                              fontSize="small"
                              style={{ color: "#0B243C" }}
                            />
                          }
                          onClick={() => {
                            setOpenEditConditionsSendOpen(true);
                          }}
                          tooltipText="Modifier"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item display="grid">
              <GenericSendButton
                errorMessages={{
                  noChange: "Veuillez modifier les paramètres avant de valider",
                  noHourRepeatQuestion: "L'heure n'est pas renseignée.",
                  noDurationRepeatQuestion: "La durée n'est pas renseignée.",
                }}
                errorSelector={{
                  noHourRepeatQuestion: "#chatbot-hour-input",
                  noDurationRepeatQuestion: "#duration-chatbot-input",
                }}
                invalidConditions={{
                  noChange: noChangeInParameters,
                  noHourRepeatQuestion:
                    openParameters?.sendEveryDay?.isActive &&
                    !openParameters?.sendEveryDay?.hour,
                  noDurationRepeatQuestion:
                    openParameters?.sendEveryDay?.isActive &&
                    !openParameters?.sendEveryDay?.duration,
                }}
                textButton="Valider"
                onSendClick={() => {
                  setChatbotData((x) => {
                    return {
                      ...x,
                      questions: x.questions.map((y) => {
                        if (y.id === openParameters.id) {
                          return openParameters;
                        }
                        return y;
                      }),
                    };
                  });
                  setOpenParameters(undefined);
                }}
              />
            </Grid>
          </Grid>
        </GenericFromPopup>
      )}
      {chatbotData?.questions?.length > 0 && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="questions">
            {(provided) => (
              <Grid
                container
                direction="column"
                marginTop="10px"
                wrap="nowrap"
                spacing="10px"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {chatbotData?.questions?.map((question, index) => (
                  <Draggable
                    key={question?.id}
                    draggableId={question?.id}
                    index={index}
                  >
                    {(provided) => (
                      <Grid
                        item
                        display="grid"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Grid
                          container
                          direction="row"
                          wrap="nowrap"
                          alignItems="center"
                          spacing="10px"
                        >
                          <Grid
                            item
                            display="grid"
                            {...provided.dragHandleProps}
                          >
                            <DragIndicatorIcon
                              fontSize="small"
                              style={{ color: "#0B243C" }}
                            />
                          </Grid>
                          <Grid
                            item
                            width="30px"
                            display="grid"
                            {...provided.dragHandleProps}
                          >
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                                color: "rgb(91, 185, 193)",
                                fontSize: "20px",
                              }}
                            >
                              {index + 1}
                            </span>
                          </Grid>
                          <Grid item xs display="grid">
                            <TextInput
                              value={question?.text}
                              onChange={(e) => {
                                const chatbotDataTmp = { ...chatbotData };
                                chatbotDataTmp.questions[index].text =
                                  e.target.value;
                                setChatbotData(chatbotDataTmp);
                              }}
                            />
                          </Grid>

                          <Grid item display="grid">
                            <Tooltip title="Paramètres">
                              <IconButton
                                onClick={() => {
                                  setOpenParameters(question);
                                }}
                                style={{
                                  margin: "0",
                                  padding: "0",
                                }}
                              >
                                <SettingsRounded
                                  fontSize="small"
                                  style={{ color: "#0B243C" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid item display="grid">
                            <Tooltip title="Supprimer">
                              <IconButton
                                onClick={() => {
                                  setChatbotData((state) => {
                                    return {
                                      ...state,
                                      questions: state.questions.filter(
                                        (x) => x?.id !== question?.id
                                      ),
                                    };
                                  });
                                }}
                                style={{
                                  margin: "0",
                                  padding: "0",
                                }}
                              >
                                <ClearRounded
                                  fontSize="small"
                                  style={{ color: "#FF3100" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default DisplayQuestions;

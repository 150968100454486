import Select from "react-select";
import Creatable from "react-select/creatable"
import * as React from 'react';
import StyleFieldError from '../../../configs/StyleFieldError.json';
import addingErrorStyleToField from "../../../utils/addingErrorStyleToField";
import FullField from "../../Fields/FullField";
import HandleErrors from "../Errors/HandleErrors";

interface IOption {
  label?: string | number,
  value?: string | number,
}

interface IMySelect {
  width?: string;
  styles?: any;
  placeholder?: string,
  isDisabled?: boolean,
  value?: IOption,
  options?: IOption[],
  components?: any,
  onChange: any,
  onBlur?: any,
  onFocus?: any,
  id?: string,
  isError?: boolean,
  className?: string,
  isClearable?: boolean,
  border?: string,
  noOptionsMessage?: any,
  isOptionDisabled?: any,
  maxMenuHeight?: any,
  name?: string,
}

export const MySelectMulti = (props: IMySelect) => {
  const style = addingErrorStyleToField({
    width: props.width,
    borderRadius: '10px',
    border: '1px solid transparent'
  }, props?.isError);

  return (
    <div className={props?.className} id={props?.id}
      style={style}>
      <div style={{ width: props.width, alignSelf: 'stretch' }}>
        <Creatable
          maxMenuHeight={props?.maxMenuHeight}
          formatCreateLabel={(userInput) => `...`}
          onFocus={props?.onFocus}
          isMulti={true}
          onBlur={props?.onBlur}
          name={props?.name}
          noOptionsMessage={props?.noOptionsMessage}
          components={props?.components}
          placeholder={props?.placeholder}
          isClearable={props?.isClearable}
          value={props?.value}
          options={props?.options}
          isOptionDisabled={props?.isOptionDisabled}
          onChange={(e) => props?.onChange(e)} />
      </div>
    </div>
  );
};

MySelectMulti.defaultProps = {
  isClearable: true,
  noOptionsMessage: () => "Aucune option n'a été trouvée...",
  width: '100%',
}

const MySelect = (props: IMySelect) => {
  const style = addingErrorStyleToField({
    width: props.width,
    borderRadius: '10px',
    border: '1px solid transparent',
    cursor: props?.isDisabled ? 'not-allowed' : 'pointer',
  }, props?.isError);

  return (
    <div className={props?.className} id={props?.id}
      style={style}>
      <div style={{ width: props.width, alignSelf: 'stretch' }}>
        <Select
          styles={props?.styles}
          maxMenuHeight={props?.maxMenuHeight}
          onFocus={props?.onFocus}
          onBlur={props?.onBlur}
          name={props?.name}
          noOptionsMessage={props?.noOptionsMessage}
          components={props?.components}
          placeholder={props?.placeholder}
          isClearable={props?.isClearable}
          value={props?.value}
          options={props?.options}
          isOptionDisabled={props?.isOptionDisabled}
          onChange={(e) => props?.onChange(e)}
          isDisabled={props?.isDisabled} />
      </div>
    </div>
  );
};

MySelect.defaultProps = {
  isClearable: true,
  noOptionsMessage: () => "Aucune option n'a été trouvée...",
  width: '100%',
}



interface IMyNewSelect {
  width?: string;
  placeholder?: string;
  isDisabled?: boolean;
  value?: IOption;
  options?: IOption[];
  components?: any;
  onChange: any;
  onBlur?: any;
  onFocus?: any;
  id?: string;
  isError?: boolean;
  className?: string;
  isClearable?: boolean;
  border?: string;
  noOptionsMessage?: any;
  isOptionDisabled?: any;
  maxMenuHeight?: any;
  name?: string;
  maxWidth?: any;
  fixedWidth?: any;
  styles?: any;
}

export const MyNewSelect = (props: IMyNewSelect) => {
  const style = addingErrorStyleToField(
    {
      width: props.width,
      borderRadius: "10px",
      border: "1px solid transparent",
      cursor: props?.isDisabled ? "not-allowed" : "pointer",
    },
    props?.isError
  );

  const customStyles = (maxWidth, fixedWidth, height = 41) => {
    return {
      control: (base, state) => ({
        ...base,
        background: "#edf2f280 0% 0% no-repeat padding-box",
        borderRadius: "10px",
        border: "1px solid #d3e0e0",
      }),
      container: (base) => ({
        ...base,
        display: "inline-block",
        width: fixedWidth ? fixedWidth : "100%",
        maxWidth: maxWidth,
      }),
      valueContainer: (base) => ({
        ...base,
        minHeight: height,
      }),
    };
  };

  return (
    <div className={props?.className} id={props?.id} style={style}>
      <div
        style={{
          width: props.width,
          alignSelf: "stretch",
        }}
      >
        <Select
          styles={props?.styles ? props.styles : customStyles(props?.maxWidth, props?.fixedWidth)}
          maxMenuHeight={props?.maxMenuHeight}
          onFocus={props?.onFocus}
          onBlur={props?.onBlur}
          name={props?.name}
          noOptionsMessage={props?.noOptionsMessage}
          components={props?.components}
          placeholder={props?.placeholder}
          isClearable={props?.isClearable}
          value={props?.value}
          options={props?.options}
          isOptionDisabled={props?.isOptionDisabled}
          onChange={(e) => props?.onChange(e)}
          isDisabled={props?.isDisabled}
        />
      </div>
    </div>
  );
};

MyNewSelect.defaultProps = {
  isClearable: true,
  noOptionsMessage: () => "Aucune option n'a été trouvée...",
  width: "100%",
  maxWidth: undefined,
};


export const NewGenericSelect: React.FC<any> = React.forwardRef(
  (
    props: {
      onChange: (e: any) => void;
      value: any;
      options: any[];
      fieldTitle: string;
      isClearable?: boolean;
      id: string;
      isMandatory?: boolean;
      placeholder: string;
      maxMenuHeight?: string;
      components?: any;
      maxWidth?: string;
      fixedWidth?: string;
    },
    ref
  ) => {
    NewGenericSelect.displayName = "NewGenericSelect";
    const {
      fieldTitle,
      value,
      onChange,
      options,
      isMandatory,
      id,
      placeholder,
      maxWidth,
      fixedWidth,
    } = props;

    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useImperativeHandle(ref, () => ({
      setIsError(value) {
        setIsError(value);
      },
    }));

    if (!isMandatory) {
      return (
        <FullField
          title={fieldTitle}
          isMandatory={isMandatory}
          spacing="1px"
        >
          <MyNewSelect
            maxMenuHeight={props.maxMenuHeight ? props.maxMenuHeight : "200px"}
            id={id}
            placeholder={placeholder ? placeholder : ""}
            value={value}
            options={options}
            components={props.components}
            isClearable={props.isClearable}
            onChange={onChange}
            maxWidth={maxWidth}
            fixedWidth={fixedWidth}
          />
        </FullField>
      );
    }
    return (
      <FullField title={fieldTitle} isMandatory={isMandatory} spacing="1px">
        <HandleErrors
          isError={isError}
          isMandatory={true}
          errorMessage={errorMessage}
        >
          <MyNewSelect
            maxMenuHeight={props.maxMenuHeight ? props.maxMenuHeight : "200px"}
            id={id}
            placeholder={placeholder ? placeholder : ""}
            isClearable={props.isClearable}
            isError={isError}
            components={props.components}
            onFocus={(e) => {
              if (isError) {
                setErrorMessage("");
                setIsError(false);
              }
            }}
            onBlur={() => {
              if (!value) {
                if (!isError) {
                  setErrorMessage("Ce champ est obligatoire.");
                  setIsError(true);
                }
              } else {
                if (isError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }
            }}
            value={value}
            options={options}
            onChange={onChange}
          />
        </HandleErrors>
      </FullField>
    );
  }
);

NewGenericSelect.defaultProps = {
  isClearable: true,
};

export default MySelect;
import { Grid } from '@mui/material';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import swal from 'sweetalert';
import FullField from '../FullField';
import { useOpenSnackbar } from '../../../pages/CreateOrEditChatbotLexPage';

const AdviceAttachField: React.FC<any> = (props) => {
  const [isZoomEffect, setIsZoomEffect] = React.useState<boolean>(false);
  const {
    adviceAttachment,
    adviceAttachmentName,
    deleteAttachField,
    updateAttachField,
    index,
  } = props;
  const openSnackbarUse = useOpenSnackbar();

  const handleFile = (e) => {
    const fileReader = new FileReader();
    if (e.target.files[0] != null) {
      if (e.target.files[0].size >= 100 * 1024 * 1024) {
        openSnackbarUse.error("Le fichier est trop lourd, il ne doit pas dépasser 100MB");
      } else if (e.target.files[0].type != "application/pdf"
        && e.target.files[0].type != "image/png"
        && e.target.files[0].type != "image/jpg"
        && e.target.files[0].type != "image/jpeg"
        && e.target.files[0].type != "video/mp3"
        && e.target.files[0].type != "video/mp4"
        && e.target.files[0].type != "video/webm") {
        swal({
          text: "Le fichier est au mauvais format",
          icon: "warning"
        });
      } else {
        fileReader.readAsDataURL(e.target.files[0]);
        fileReader.onload = (event) => {
          const newAttachment = event.target.result;
          const newAttachmentName = e.target.files[0].name;
          updateAttachField(index, newAttachment, newAttachmentName);
        }
      }
    }
  }

  const isImage = () => {
    const str = adviceAttachmentName;
    const formats = ['png', 'jpg', 'jpeg', 'svg', 'ico', 'gif', 'apng'];
    if (!str || str?.length === 0) return (false);
    for (const format of formats) {
      if (str?.endsWith(format))
        return (true);
    }
    return (false);
  }

  const isPdf = () => {
    const str = adviceAttachmentName;
    const formats = ['pdf'];
    if (!str || str?.length === 0) return (false);
    for (const format of formats) {
      if (str?.endsWith(format))
        return (true);
    }
    return (false);
  }

  return (
    <FullField title='Joindre un fichier' isMandatory={false}>
      {adviceAttachment &&
        <Grid item display='grid' alignItems='center' justifyContent='center' paddingTop='4px' >
          <Grid container direction='column' wrap="nowrap" display='flex' border='2px dashed #8d9899' borderRadius='15px' padding='15px'>
            <Grid item display='grid'>
              <Grid container direction='row' justifyContent='space-between' alignItems='center' spacing='10px'>
                <Grid item display='grid' alignItems='center'>
                  <span
                    className='text_champs'>
                    {adviceAttachmentName}
                  </span>
                </Grid>
                <Grid item display='grid' alignItems='center'>
                  <button
                    onClick={() => deleteAttachField()}
                    className={'delete_advice_button'}>
                    Supprimer
                  </button>
                </Grid>
              </Grid>
            </Grid>
            {isImage() &&
              <Grid item display='grid' alignItems='center' justifyContent='center'>
                <img className={isZoomEffect ? 'img-zoom-effect-loaded' : 'img-zoom-effect'}
                  onClick={() => setIsZoomEffect(!isZoomEffect)}
                  style={{ borderRadius: '20px', maxWidth: '330px' }}
                  src={adviceAttachment}
                  alt=''
                  key={adviceAttachment} />
              </Grid>
            }
            {isPdf() &&
              <Grid item display='grid' alignItems='center' justifyContent='center' style={{ resize: 'vertical', maxHeight: '1000px', minHeight: '300px', overflow: 'auto' }}>
                <iframe
                  style={{ borderRadius: '20px', minWidth: '500px', maxHeight: '1000px', minHeight: '300px', height: '100%', resize: 'vertical', overflow: 'hidden' }}
                  src={adviceAttachment} />
              </Grid>
            }
          </Grid>
        </Grid>
      }
      {!adviceAttachment &&
        <Grid item display='grid' style={{ width: '100%' }}>
          <Dropzone
            onDrop={() => { /* Non géré */ return (null) }}>
            {({ getRootProps, getInputProps }) => (
              <div className="file_modals" style={{ width: '100%', margin: '0', flexDirection: 'column', padding: '0', justifyContent: 'center', display: 'flex', cursor: 'unset' }} {...getRootProps()}>
                <div className="modal_file_button" style={{ alignItems: 'center', display: 'grid', justifyContent: 'center', padding: '0', marginTop: '0px', cursor: 'unset' }}>
                  <div className="modal_file_button_text" style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row', cursor: 'unset' }}>
                    <input type="file" name={`file${index}`} id={`file${index}`} onChange={
                      (e) => {
                        handleFile(e);
                        //props?.handleFile(e, props?.index);
                      }}
                      style={{ display: 'none', cursor: 'unset' }} />
                    <label style={{ cursor: 'pointer' }} htmlFor={`file${index}`}>Joindre un fichier (image, vidéo ou document)</label>
                  </div>
                </div>
                <div className="accept_format">
                  Formats acceptés : pdf, png, jpeg, jpg, mp3, mp4, webm. Maxi 100mo.
                </div>
                {/* <p>Ou glisser / déposer ici le fichier</p> */}
                <br />
              </div>)}
          </Dropzone>
        </Grid>
      }
    </FullField>
  );
};

export default AdviceAttachField;
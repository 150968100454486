import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";
import BoxTitle from "../components/Library/Box/BoxTitle";
import AdministrationBox from "../components/MesReglagesBox/AdministrationBox";
import MesReglagesBox from "../components/MesReglagesBox/MesReglagesBox";
import FullPage from "./FullPage";
import useAppSelector from "../redux/useAppSelector";
import {
  GenericTextInput,
  useOpenSnackbar,
} from "./CreateOrEditChatbotLexPage";
import makeAPIRequest from "../utils/makeAPIRequest";
import { DrawMyFacture, FactureInvoicePlus } from "./AdminOthersStatisticsPage";
import { MoreOrLessButtons } from "./ChatbotLexPage";

const FacturesBox: React.FC<any> = (props) => {
  const snackbarOpenUse = useOpenSnackbar();
  const [myFactures, setMyFactures] = React.useState<FactureInvoicePlus[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [maxItem, setMaxItem] = React.useState<number>(10);

  React.useEffect(() => {
    setIsLoading(true);
    makeAPIRequest("get", "/my_facturations/all", null, "v3")
      .then((res) => {
        setMyFactures(res?.data);
      })
      .catch((err) => {
        snackbarOpenUse.error("Impossible de récupérer vos factures", err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const filteredFactures = [...myFactures];

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      display="flex"
      paddingLeft="41px"
      paddingTop="26px"
      paddingRight="41px"
      paddingBottom="60px"
      maxWidth="1360px"
      minWidth="600px"
      style={{
        background: "rgb(247, 252, 252, 0.55) 0% 0% no-repeat padding-box",
        borderRadius: "24px",
      }}
    >
      <Grid item display="grid">
        <BoxTitle first="Mes" second="factures" />
      </Grid>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="8%"
        paddingRight="8%"
      >
        <Grid container direction="column" wrap="nowrap" gap="10px">
          {!isLoading ? (
            <>
              {filteredFactures ? (
                <Grid container direction="column" wrap="nowrap" gap="10px">
                  {filteredFactures?.length == 0 && (
                    <Grid item display="grid">
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#0B243C",
                          fontFamily: "Poppins",
                        }}
                      >{`Aucune facture n'a été trouvée.`}</span>
                    </Grid>
                  )}
                  {filteredFactures.slice(0, maxItem).map((facture) => {
                    return (
                      <Grid item display="grid" key={facture?.id}>
                        <DrawMyFacture
                          facture={facture}
                          userView={true}
                          onFactureUpdate={(newFacture) => {
                            return;
                          }}
                        />
                      </Grid>
                    );
                  })}
                  {filteredFactures?.length > 0 && (
                    <MoreOrLessButtons
                      maxItem={maxItem}
                      setMaxItem={setMaxItem}
                      items={filteredFactures}
                      marginTop="0px"
                    />
                  )}
                </Grid>
              ) : (
                <span>Il n'y a aucune facture à afficher.</span>
              )}
            </>
          ) : (
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const FacturesPage: React.FC<any> = (props) => {
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );

  return (
    <FullPage>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="center"
        spacing="20px"
        paddingLeft="24px"
        paddingRight="24px"
      >
        <Grid item display="grid" alignItems="flex-start">
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <MesReglagesBox page="factures" />
            </Grid>
            {props?.user?.account_type == "4" && (
              <Grid item display="grid" paddingTop="30px">
                <AdministrationBox page="factures" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs
          display="grid"
          alignItems="flex-start"
          marginLeft="24px"
          width={windowWidth > 1300 ? "1000px" : null}
        >
          <FacturesBox {...props} />
        </Grid>
      </Grid>
    </FullPage>
  );
};

export default FacturesPage;

import React, { useState } from "react";
import IconLoupe from "../assets/IconLoupe.png";
import { ReactComponent as IconCreate } from "../assets/IconCreate.svg";
import EventEmitter from "../services/event";
import "../styles/tutorialList.scss";
import TabsContainer from "./MesConseilsBox/TabsContainer/TabsContainer";
import { addSearchTab, setActiveTab } from "../redux/tabsReducer";
import { useDispatch } from "react-redux";
import CreateOrEditAdvice from "./FormPopup/CreateOrEditAdvice";
import * as createAdviceReducer from "../redux/createAdviceReducer";
import { Grid, IconButton, Tooltip } from "@mui/material";
import useUtils from "../utils/hooks/useUtils";
import { SelectActualContainer } from "./SelectAccount";
import { useHistory } from "react-router";
import {
  FormsAdminPanel,
  FormsSettings,
  QuestionnaireBox,
} from "../pages/QuestionnairePage";
import {
  AdminPanelSettingsRounded,
  SettingsRounded,
} from "@mui/icons-material";
import ChatbotsAdminPanel from "./Chatbot/ChatbotsAdminPanel";
import useQuery from "../utils/hooks/useQuery";
import ProtocolesAdminPanel from "./Protocoles/ProtocolesAdminPanel";
import ChatbotBox from "./Chatbot/ChatbotBox";
import { ChatbotLexAdminPanel, ChatbotLexBox } from "../pages/ChatbotLexPage";
import useAppSelector from "../redux/useAppSelector";

export type actualContainerType =
  | "Mes Conseils"
  | "Mes Questionnaires"
  | "Mes Questionnaires Interactifs"
  | "Mes Protocoles"
  | "Mes Chatbots";

export type TypeTitle =
  | ["Mes", "Conseils"]
  | ["Mes", "Questionnaires"]
  | ["Mes", "Questionnaires Interactifs"]
  | ["Mes", "Protocoles"]
  | ["Mes", "Chatbots"];

export const MySimpleCreateButton: React.FC<{
  text: string;
  addId?: string;
  onClick: () => void;
  minWidthForText?: number;
}> = (props) => {
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );

  return (
    <button
      id={props.addId}
      style={{
        boxShadow: "2px 6px 10px #0F213467",
        paddingTop: "7px",
        paddingBottom: "7px",
        paddingLeft: "20px",
        maxWidth: "330px",
        paddingRight: "20px",
        borderRadius: "18px",
        opacity: 1,
        background: "#0B243C 0% 0% no-repeat padding-box",
        letterSpacing: "0px",
        color: "#FFFFFF",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "12px",
        whiteSpace: "nowrap",
        fontFamily: "Poppins",
        justifySelf: "flex-end",
      }}
      onClick={props.onClick}
    >
      <Grid item display="grid">
        <Grid
          container
          spacing="7px"
          direction="row"
          display="flex"
          wrap="nowrap"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item display="grid">
            <IconCreate />
          </Grid>
          {windowWidth > props.minWidthForText && (
            <Grid item display="grid">
              <span>{props.text}</span>
            </Grid>
          )}
        </Grid>
      </Grid>
    </button>
  );
};

MySimpleCreateButton.defaultProps = {
  minWidthForText: 800,
};

interface IHomeContainer {
  containerTitle?: TypeTitle;
  rightButton: JSX.Element;
  containerContent: JSX.Element;
  rightsButton?: JSX.Element;
  actualContainer: actualContainerType;
  setActualContainerType: React.Dispatch<
    React.SetStateAction<actualContainerType>
  >;
}

const HomeContainer: React.FC<IHomeContainer> = (props) => {
  return (
    <div className="container">
      <div className="list row">
        <Grid
          container
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item display="grid">
            <Grid container direction="row" wrap="nowrap">
              {props.rightsButton && (
                <Grid item display="grid" paddingRight="10px">
                  {props.rightsButton}
                </Grid>
              )}
              <span
                style={{ fontSize: "26px" }}
                className="title-card font-link-blue-bold"
              >
                {props.containerTitle[0]}
                <span className="font-link-bold" style={{ color: "#0B243C" }}>
                  {" "}
                  {props.containerTitle[1]}
                </span>
              </span>
              <SelectActualContainer
                actualContainer={props.actualContainer}
                setActualContainerType={props.setActualContainerType}
              />
            </Grid>
          </Grid>
          <Grid item xs display="grid">
            {props.rightButton}
          </Grid>
        </Grid>
      </div>
      {props.containerContent}
    </div>
  );
};

const TutorialsList: React.FC<any> = (props) => {
  const [
    utils,
    setUtils,
    professionOptions,
    civilitiesOptions,
    specialitiesOptions,
    nniTypesOptions,
  ] = useUtils();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const activeTab = useAppSelector((state) => state.tabsReducer.activeTab);
  const [searchArary, setSearchArray] = useState<any>([]);
  const [searched, setSearched] = useState(false);
  const [activeKey, setActiveKey] = useState("recents");
  const [isProtocolesAdminPanelOpen, setIsProtocolesAdminPanelOpen] =
    React.useState<boolean>(false);
  const [isChatbotsAdminPanelOpen, setIsChatbotsAdminPanelOpen] =
    React.useState<boolean>(false);
  const [isChatbotsLexAdminPanelOpen, setIsChatbotsLexAdminPanelOpen] =
    React.useState<boolean>(false);
  const [isFormsAdminPanelOpen, setIsFormsAdminPanelOpen] =
    React.useState<boolean>(false);
  const [isFormsSettingsOpen, setIsFormsSettingsOpen] =
    React.useState<boolean>(false);
  const [count, setCount] = useState([]);
  const [actualContainer, setActualContainer] =
    React.useState<actualContainerType>("Mes Conseils");
  const [, fctMiseAJour] = React.useState({});
  const [toMuch, setToMuch] = useState<boolean>(false);
  const miseAJour = React.useCallback(() => fctMiseAJour({}), []);
  const [checked, setChecked] = useState<any>({});
  const [filterKey, setFilterKey] = useState({
    spec: null,
    conseil: null,
    attchment: null,
    loading: true,
  });
  const [filterKeyFavourite, setFilterKeyFavourite] = useState({
    spec: null,
    conseil: null,
    attchment: null,
  });
  const [filterKeySearch, setFilterKeySearch] = useState({
    spec: null,
    conseil: null,
    attchment: null,
  });
  const [lenght, setLength] = useState(0);
  const [filterStatus, setFilterStatus] = useState<any>({});
  const isEditAdviceOpen = useAppSelector(
    (state) => state.editAdviceReducer.isAdviceOpen
  );
  const isCreateAdviceOpen = useAppSelector(
    (state) => state.createAdviceReducer.isAdviceOpen
  );
  const accesses: {
    forms: string;
    chatbots: string;
    protocoles: string;
    chatbots_lex: string;
  } = useAppSelector((state) => state.authorizationsReducer);
  const user = useAppSelector((x) => x.userReducer.user);
  const displaySpec = useAppSelector((x) => x.languageReducer.displaySpec);
  const dispatch = useDispatch();

  const functionality = useQuery().get("f");
  const favorisAutoQuery = useQuery().get("favorisAuto");

  React.useEffect(() => {
    if (!favorisAutoQuery) {
      return;
    }
    if (favorisAutoQuery === "oui") {
      dispatch(setActiveTab("favourite"));
    }
  }, [favorisAutoQuery]);

  React.useEffect(() => {
    if (!displaySpec || !Array.isArray(displaySpec)) {
      return;
    }
    if (!user?.speciality_main_id) {
      return;
    }
    const specUser = parseInt(user?.speciality_main_id);
    if (isNaN(specUser)) {
      setFilterKey((x) => {
        return { ...x, loading: true };
      });
      return;
    }
    const founded = displaySpec.find((x) =>
      x?.specialities?.includes(specUser)
    );
    if (!founded?.value) {
      setFilterKey((x) => {
        return { ...x, loading: true };
      });
      return;
    }
    setFilterKey((x) => {
      return { ...x, spec: founded.value, loading: false };
    });
  }, [user, displaySpec]);

  React.useEffect(() => {
    if (functionality === "questionnaires") {
      setActualContainer("Mes Questionnaires");
    } else if (functionality === "questionnaires_interactifs") {
      setActualContainer("Mes Questionnaires Interactifs");
    } else if (functionality === "protocoles") {
      setActualContainer("Mes Protocoles");
    } else if (functionality === "conseils") {
      setActualContainer("Mes Conseils");
    } else if (functionality === "chatbots") {
      setActualContainer("Mes Chatbots");
    }
  }, [functionality]);

  const dataToSend = {
    search: search,
    speciality: "",
    attachement: "",
    type: "",
  };

  const fetchData = async () => {
    /*
    await makeAPIRequest("post", "/conseils/search", dataToSend, `v3`).then(
      (res: any) => {
        if (lenght <= 2) {
          setLength(lenght + 1);
          setCount((arr) => [...arr, lenght]);
          setToMuch(false);
        } else {
          setToMuch(true);
        }
        setSearchArray({
          ...searchArary,
          [lenght]: {
            text: search,
            taille:
              res.data.data.search_conseils_results.search_conseils.length,
          },
        });
        EventEmitter.emit("searched", {
          value: res.data.data,
          text: search,
          taile: lenght,
        });
        miseAJour();
      }
    );
    */
  };

  const toggleEndTab = (n) => {
    const index = count.indexOf(n);
    let temp = searchArary;
    const temp2 = count;
    setLength(lenght - 1);

    if (index > -1) {
      count.splice(index, 1);
      if (index == 0) {
        temp = { [0]: searchArary[n], ...temp };
      }
    } else count.pop();
    delete temp[n];

    setCount(temp2);
    setSearchArray(temp);
  };

  const emit = (name, value) => {
    EventEmitter.emit(name, {
      value: value,
    });
  };

  const clearFilters = () => {
    const filterStatusCleaned = {};
    const page = activeTab === "favourite" ? "fav" : null;
    const filterKeyCleaned = {
      spec: "",
      conseil: "",
      attchment: "",
    };
    setFilterStatus(filterStatusCleaned);
    if (activeTab === "favourite") {
      setFilterKeyFavourite(filterKeyCleaned);
      emit("filter", {
        value: filterKeyCleaned,
        status: filterStatusCleaned,
        page: page,
      });
    } else {
      emit("filter", {
        value: filterKeyCleaned,
        status: filterStatusCleaned,
        page: page,
      });
      setFilterKey(filterKeyCleaned as any);
    }
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const newKey = { spec: null, conseil: null, attchment: null };
    let page;

    if (activeTab === "favourite") {
      page = "fav";
      Object.assign(newKey, filterKeyFavourite);
      newKey[name] = value;
      setFilterKeyFavourite(newKey);
    } else {
      Object.assign(newKey, filterKey);
      newKey[name] = value;
      setFilterKey(newKey as any);
    }
    let tempStatus = filterStatus;
    if (name == "spec") {
      if (value != "") {
        tempStatus = {
          ...tempStatus,
          Spec: true,
        };
      } else {
        tempStatus = {
          ...tempStatus,
          Spec: false,
        };
      }
    }
    if (name == "conseil") {
      if (value != "") {
        tempStatus = {
          ...tempStatus,
          Conseil: true,
        };
      } else {
        tempStatus = {
          ...tempStatus,
          Conseil: false,
        };
      }
    }
    if (name == "piece_jointe") {
      if (value != "") {
        tempStatus = {
          ...tempStatus,
          Attchment: true,
        };
      } else {
        tempStatus = {
          ...tempStatus,
          Attchment: false,
        };
      }
    }
    setFilterStatus(tempStatus);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearch(value);
  };

  const handleSumbit = (e) => {
    e.preventDefault();

    dispatch(
      addSearchTab({
        title: search,
      })
    );

    EventEmitter.emit("search", {
      value: e.target.value,
    });

    fetchData();
  };

  return (
    <>
      {isEditAdviceOpen && (
        <CreateOrEditAdvice
          isEdit={true}
          isCreate={false}
          specialitiesOptions={specialitiesOptions}
        />
      )}
      {isCreateAdviceOpen && (
        <CreateOrEditAdvice
          isEdit={false}
          isCreate={true}
          specialitiesOptions={specialitiesOptions}
        />
      )}
      <HomeContainer
        containerTitle={(() => {
          const convert = {
            "Mes Conseils": ["Mes", "Conseils"] as TypeTitle,
            "Mes Questionnaires": ["Mes", "Questionnaires"] as TypeTitle,
            "Mes Questionnaires Interactifs": [
              "Mes",
              "Questionnaires Interactifs",
            ] as TypeTitle,
            "Mes Protocoles": ["Mes", "Protocoles"] as TypeTitle,
            "Mes Chatbots": ["Mes", "Chatbots"] as TypeTitle,
          };
          if (convert?.[actualContainer]) {
            return convert[actualContainer];
          }
          return ["Mes", "Conseils"] as TypeTitle;
        })()}
        rightsButton={(() => {
          if (actualContainer === "Mes Questionnaires") {
            return (
              <Grid container direction="row" wrap="nowrap" gap="5px">
                {accesses?.forms === "admin" && (
                  <Grid item display="grid">
                    <Tooltip title="Gérer les droits">
                      <IconButton
                        onClick={() => setIsFormsAdminPanelOpen(true)}
                        style={{ padding: "0" }}
                      >
                        <AdminPanelSettingsRounded
                          style={{
                            height: "28px",
                            width: "28px",
                            color: "#0B243C",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item display="grid">
                  <Tooltip title="Paramètres">
                    <IconButton
                      onClick={() => setIsFormsSettingsOpen(true)}
                      style={{ padding: "0" }}
                    >
                      <SettingsRounded
                        style={{
                          height: "26px",
                          width: "26px",
                          color: "#0B243C",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            );
          } else if (
            actualContainer === "Mes Questionnaires Interactifs" &&
            accesses?.chatbots === "admin"
          ) {
            return (
              <Tooltip title="Gérer les droits">
                <IconButton
                  onClick={() => setIsChatbotsAdminPanelOpen(true)}
                  style={{ padding: "0" }}
                >
                  <AdminPanelSettingsRounded
                    style={{
                      height: "28px",
                      width: "28px",
                      color: "#0B243C",
                    }}
                  />
                </IconButton>
              </Tooltip>
            );
          } else if (
            actualContainer === "Mes Chatbots" &&
            accesses?.chatbots_lex === "admin"
          ) {
            return (
              <Tooltip title="Gérer les droits">
                <IconButton
                  onClick={() => setIsChatbotsLexAdminPanelOpen(true)}
                  style={{ padding: "0" }}
                >
                  <AdminPanelSettingsRounded
                    style={{
                      height: "28px",
                      width: "28px",
                      color: "#0B243C",
                    }}
                  />
                </IconButton>
              </Tooltip>
            );
          } else if (
            actualContainer === "Mes Protocoles" &&
            accesses?.protocoles === "admin"
          ) {
            return (
              <Tooltip title="Gérer les droits">
                <IconButton
                  onClick={() => setIsProtocolesAdminPanelOpen(true)}
                  style={{ padding: "0" }}
                >
                  <AdminPanelSettingsRounded
                    style={{
                      height: "28px",
                      width: "28px",
                      color: "#0B243C",
                    }}
                  />
                </IconButton>
              </Tooltip>
            );
          }
          return <></>;
        })()}
        actualContainer={actualContainer}
        setActualContainerType={setActualContainer}
        rightButton={(() => {
          if (actualContainer === "Mes Conseils") {
            return (
              <>
                {user?.interface && !["8742"].includes(user?.interface) && (
                  <MySimpleCreateButton
                    text="Créer un nouveau conseil"
                    onClick={() =>
                      dispatch(createAdviceReducer?.openAdvice(null))
                    }
                  />
                )}
              </>
            );
          } else if (actualContainer === "Mes Questionnaires") {
            return (
              <MySimpleCreateButton
                text="Créer un questionnaire"
                onClick={() => {
                  history.push("/create_questionnaire");
                }}
              />
            );
          } else if (actualContainer === "Mes Questionnaires Interactifs") {
            return (
              <>
                {user?.interface && !["8742"].includes(user?.interface) && (
                  <MySimpleCreateButton
                    minWidthForText={1000}
                    text="Créer un questionnaire interactif"
                    onClick={() => {
                      history.push("/create_questionnaire_interactif");
                    }}
                  />
                )}
              </>
            );
          } else if (actualContainer === "Mes Protocoles") {
            return (
              <MySimpleCreateButton
                text="Créer un protocole"
                onClick={() => {
                  return;
                }}
              />
            );
          } else if (actualContainer === "Mes Chatbots") {
            return (
              <MySimpleCreateButton
                text="Créer un chatbot"
                onClick={() => {
                  history.push("/create_chatbot");
                }}
              />
            );
          } else {
            return <></>;
          }
        })()}
        containerContent={(() => {
          if (actualContainer === "Mes Questionnaires Interactifs") {
            return (
              <>
                {isChatbotsAdminPanelOpen && (
                  <ChatbotsAdminPanel
                    setIsChatbotsAdminPanelOpen={setIsChatbotsAdminPanelOpen}
                  />
                )}
                <ChatbotBox chatbotsAccess={accesses?.chatbots} />
              </>
            );
          } else if (actualContainer === "Mes Protocoles") {
            return (
              <>
                {isProtocolesAdminPanelOpen && (
                  <ProtocolesAdminPanel
                    setIsProtocolesAdminPanelOpen={
                      setIsProtocolesAdminPanelOpen
                    }
                  />
                )}
              </>
            );
          } else if (actualContainer === "Mes Chatbots") {
            return (
              <>
                {isChatbotsLexAdminPanelOpen && (
                  <ChatbotLexAdminPanel
                    setIsChatbotsLexAdminPanelOpen={
                      setIsChatbotsLexAdminPanelOpen
                    }
                  />
                )}
                <ChatbotLexBox chatbotsLexAccess={accesses.chatbots_lex} />
              </>
            );
          } else if (actualContainer === "Mes Questionnaires") {
            return (
              <>
                {isFormsAdminPanelOpen && (
                  <FormsAdminPanel
                    setIsFormsAdminPanelOpen={setIsFormsAdminPanelOpen}
                  />
                )}
                {isFormsSettingsOpen && (
                  <FormsSettings
                    setIsFormsSettingsOpen={setIsFormsSettingsOpen}
                  />
                )}
                <QuestionnaireBox formsAccess={accesses.forms} />
              </>
            );
          } else if (actualContainer === "Mes Conseils") {
            return (
              <>
                <div
                  style={{
                    paddingTop: "23px",
                  }}
                />
                {user?.interface && !["8742"].includes(user?.interface) && (
                  <div className="row">
                    <span
                      className="font-link"
                      style={{
                        fontSize: "16px",
                        color: "#657273",
                        margin: "0",
                      }}
                    >
                      Saisir vos mots-clés ou coller un texte :
                    </span>
                    <div className="col-md-12">
                      <form action="/" method="GET" className="form">
                        <input
                          type="search"
                          placeholder="Rhume lavage nez ; Protocole anesthésie générale…"
                          style={{ fontStyle: "italic" }}
                          className="search-field"
                          onChange={handleInputChange}
                        />
                        <button
                          type="submit"
                          className="search-button"
                          onClick={(e) => handleSumbit(e)}
                        >
                          <img src={IconLoupe} alt="" />
                        </button>
                      </form>
                    </div>
                  </div>
                )}
                <TabsContainer
                  activeKey={activeKey}
                  setActiveKey={setActiveKey}
                  filterKey={filterKey}
                  filterKeyFavourite={filterKeyFavourite}
                  filterKeySearch={filterKeySearch}
                  setFilterKeySearch={setFilterKeySearch}
                  clearFilters={clearFilters}
                  handleSelectChange={handleSelectChange}
                  count={count}
                  searchArary={searchArary}
                  toggleEndTab={toggleEndTab}
                  lenght={lenght}
                  searched={searched}
                  searchTexts={search}
                  {...props}
                />
              </>
            );
          } else {
            return <></>;
          }
        })()}
      />
    </>
  );
};

export default TutorialsList;

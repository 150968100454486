import React, { useState } from "react";
import { Grid, IconButton, Tooltip, Button } from "@mui/material";
import ClearRounded from "@mui/icons-material/ClearRounded";
import { nanoid } from "nanoid";
import AddButton from "./Library/Buttons/AddButton/AddButton";
import { GenericSelect } from "../pages/QuestionnairePage";
import { GenericSendButton } from "../pages/ChatbotLexPage";
import { GenericTextInput } from "../pages/CreateOrEditChatbotLexPage";
import { ButtonIconWithTooltip } from "./Questionnaire/PDF/CreateOrEditPDFQuestionnaire";
import {
  autoSetFloat,
  autoSetFloatWithCallback,
} from "../pages/AdminOthersStatisticsPage";
import { NewGenericSelect } from "./Library/Select/MySelect";
import resizeText from "../utils/resizeText";

// Types de conditions
type ConditionOperator = "=" | "!=" | ">" | "<" | ">=" | "<=" | "contains";
type LogicalOperator = "AND" | "OR";

const operatorLogicOptions = [
  {
    label: "ET",
    value: "AND",
  },
  {
    label: "OU",
    value: "OR",
  },
];

const conditionOperatorOptions = [
  {
    value: "=",
    label: "Égal à",
  },
  {
    value: "!=",
    label: "Différent de",
  },
  {
    value: ">",
    label: "Supérieur à",
  },
  {
    value: "<",
    label: "Inférieur à",
  },
  {
    value: ">=",
    label: "Supérieur ou égal à",
  },
  {
    value: "<=",
    label: "Inférieur ou égal à",
  },
  {
    value: "contains",
    label: "Contient",
  },
];

interface SimpleCondition {
  id: string;
  type: "simple";
  operator: ConditionOperator;
  value: string;
  option?: string;
}

interface CompositeCondition {
  id: string;
  type: "composite";
  operator: LogicalOperator;
  conditions: Condition[];
}

interface ThresholdCondition {
  id: string;
  type: "threshold";
  min?: number;
  max?: number;
  conditions: Condition[];
}

const DrawSeuilCondition: React.FC<{
  seuilCondition: ThresholdCondition;
  updateSeuilCondition: (newCompositeCondition: ThresholdCondition) => void;
  deleteClick: () => void;
  options: { id: string; text: string }[];
}> = (props) => {
  const { seuilCondition, updateSeuilCondition, deleteClick, options } = props;

  const addSimpleCondition = () => {
    const newCondition: SimpleCondition = {
      operator: "=",
      type: "simple",
      value: "",
      option: undefined,
      id: nanoid(),
    };
    updateSeuilCondition({
      ...seuilCondition,
      conditions: [...seuilCondition.conditions, newCondition],
    });
  };

  const addCompositeCondition = () => {
    const newCondition: CompositeCondition = {
      type: "composite",
      operator: "AND",
      conditions: [],
      id: nanoid(),
    };
    updateSeuilCondition({
      ...seuilCondition,
      conditions: [...seuilCondition.conditions, newCondition],
    });
  };

  const addThresholdCondition = () => {
    const newCondition: ThresholdCondition = {
      type: "threshold",
      min: 1,
      max: 1,
      conditions: [],
      id: nanoid(),
    };
    updateSeuilCondition({
      ...seuilCondition,
      conditions: [...seuilCondition.conditions, newCondition],
    });
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      width={"fit-content"}
      gap="5px"
    >
      <Grid item display="grid" justifySelf={"flex-start"}>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          gap="5px"
          alignItems={"center"}
        >
          <Grid item display="grid">
            <ButtonIconWithTooltip
              tooltipText="Supprimer"
              Icon={
                <ClearRounded
                  fontSize="small"
                  style={{ color: "#FF3100", fontSize: "12px" }}
                />
              }
              onClick={() => {
                deleteClick();
              }}
            />
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                fontSize: "12px",
                color: "#657273",
                textAlign: "left",
              }}
            >
              Condition "par seuil"
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="grid">
        <Grid container direction="row" gap="10px" alignItems={"center"}>
          <Grid item display="grid">
            <span>Entre </span>
          </Grid>
          <Grid item display="grid" style={{ width: "63px" }}>
            <GenericTextInput
              fieldTitle=""
              id="min-field"
              onChange={(e) => {
                autoSetFloatWithCallback(e, (value) => {
                  const toInt = parseInt(value);
                  updateSeuilCondition({
                    ...seuilCondition,
                    min:
                      toInt === undefined ||
                      isNaN(toInt) ||
                      toInt < 0 ||
                      toInt > 99
                        ? undefined
                        : toInt,
                  });
                });
              }}
              value={
                seuilCondition?.min !== undefined ? seuilCondition.min : ""
              }
              isMandatory={false}
              placeholder="0"
            />
          </Grid>
          <Grid item display="grid">
            <span>et </span>
          </Grid>
          <Grid item display="grid" style={{ width: "63px" }}>
            <GenericTextInput
              fieldTitle=""
              id="max-field"
              onChange={(e) => {
                autoSetFloatWithCallback(e, (value) => {
                  const toInt = parseInt(value);
                  updateSeuilCondition({
                    ...seuilCondition,
                    max:
                      toInt === undefined ||
                      isNaN(toInt) ||
                      toInt < 0 ||
                      toInt > 99
                        ? undefined
                        : toInt,
                  });
                });
              }}
              value={
                seuilCondition?.max !== undefined ? seuilCondition.max : ""
              }
              isMandatory={false}
              placeholder="∞"
            />
          </Grid>
          <Grid item display="grid">
            <span>conditions valides</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="grid" paddingTop="10px">
        <Grid
          container
          direction="row"
          wrap="nowrap"
          gap="10px"
          alignItems={"center"}
        >
          <Grid item display="grid">
            <span style={{ fontSize: "16px" }}>Ajouter une condition : </span>
          </Grid>
          <Grid item display="grid">
            <AddButton
              text={"simple"}
              displayIconCreate={false}
              displayText={true}
              onClick={() => {
                addSimpleCondition();
              }}
            />
          </Grid>
          <Grid item display="grid">
            <AddButton
              text={"composite"}
              displayIconCreate={false}
              displayText={true}
              onClick={() => {
                addCompositeCondition();
              }}
            />
          </Grid>
          <Grid item display="grid">
            <AddButton
              displayIconCreate={false}
              displayText={true}
              text={"par seuil"}
              onClick={() => {
                addThresholdCondition();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="grid" paddingTop="15px">
        {seuilCondition.conditions.length === 0 ? (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            gap="10px"
            padding="20px"
            borderRadius={"20px"}
            width={"fit-content"}
            border="1px solid rgba(0, 0, 0, 0.3)"
          >
            <Grid item display="grid">
              <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                Aucune condition n'a été ajoutée, commencez par en ajouter une.
              </span>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            gap="10px"
            padding="20px"
            borderRadius={"20px"}
            width={"fit-content"}
            border="1px solid rgba(0, 0, 0, 0.3)"
          >
            {seuilCondition.conditions.map((conditionTmp, index) => {
              if (conditionTmp.type === "simple") {
                const condition: SimpleCondition = conditionTmp;
                return (
                  <Grid item display="grid" key={condition?.id}>
                    <DrawSimpleCondition
                      options={options}
                      simpleCondition={condition}
                      deleteClick={() => {
                        updateSeuilCondition({
                          ...seuilCondition,
                          conditions: seuilCondition.conditions.filter(
                            (y) => y?.id !== condition?.id
                          ),
                        });
                      }}
                      updateSimpleCondition={(updatedCondition) => {
                        updateSeuilCondition({
                          ...seuilCondition,
                          conditions: seuilCondition.conditions.map((y) => {
                            if (y?.id === updatedCondition?.id) {
                              return updatedCondition;
                            }
                            return y;
                          }),
                        });
                      }}
                    />
                  </Grid>
                );
              } else if (conditionTmp.type === "composite") {
                const condition: CompositeCondition = conditionTmp;
                return (
                  <Grid item display="grid" key={condition?.id}>
                    <DrawCompositeCondition
                      options={options}
                      compositeCondition={condition}
                      updateCompositeCondition={(updatedCondition) => {
                        updateSeuilCondition({
                          ...seuilCondition,
                          conditions: seuilCondition.conditions.map((y) => {
                            if (y?.id === updatedCondition?.id) {
                              return updatedCondition;
                            }
                            return y;
                          }),
                        });
                      }}
                      deleteClick={() => {
                        updateSeuilCondition({
                          ...seuilCondition,
                          conditions: seuilCondition.conditions.filter(
                            (y) => y?.id !== condition?.id
                          ),
                        });
                      }}
                    />
                  </Grid>
                );
              } else if (conditionTmp.type === "threshold") {
                const condition: ThresholdCondition = conditionTmp;
                return (
                  <Grid item display="grid" key={condition?.id}>
                    <DrawSeuilCondition
                      options={options}
                      seuilCondition={condition}
                      updateSeuilCondition={(updatedCondition) => {
                        updateSeuilCondition({
                          ...seuilCondition,
                          conditions: seuilCondition.conditions.map((y) => {
                            if (y?.id === updatedCondition?.id) {
                              return updatedCondition;
                            }
                            return y;
                          }),
                        });
                      }}
                      deleteClick={() => {
                        updateSeuilCondition({
                          ...seuilCondition,
                          conditions: seuilCondition.conditions.filter(
                            (y) => y?.id !== condition?.id
                          ),
                        });
                      }}
                    />
                  </Grid>
                );
              }
              return <></>;
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const DrawCompositeCondition: React.FC<{
  compositeCondition: CompositeCondition;
  updateCompositeCondition: (newCompositeCondition: CompositeCondition) => void;
  deleteClick: () => void;
  options: { id: string; text: string }[];
}> = (props) => {
  const { compositeCondition, updateCompositeCondition, deleteClick, options } =
    props;

  const addSimpleCondition = () => {
    const newCondition: SimpleCondition = {
      operator: "=",
      type: "simple",
      value: "",
      option: undefined,
      id: nanoid(),
    };
    updateCompositeCondition({
      ...compositeCondition,
      conditions: [...compositeCondition.conditions, newCondition],
    });
  };

  const addCompositeCondition = () => {
    const newCondition: CompositeCondition = {
      type: "composite",
      operator: "AND",
      conditions: [],
      id: nanoid(),
    };
    updateCompositeCondition({
      ...compositeCondition,
      conditions: [...compositeCondition.conditions, newCondition],
    });
  };

  const addThresholdCondition = () => {
    const newCondition: ThresholdCondition = {
      type: "threshold",
      min: 1,
      max: 1,
      conditions: [],
      id: nanoid(),
    };
    updateCompositeCondition({
      ...compositeCondition,
      conditions: [...compositeCondition.conditions, newCondition],
    });
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      width={"fit-content"}
      gap="5px"
    >
      <Grid item display="grid" justifySelf={"flex-start"}>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          gap="5px"
          alignItems={"center"}
        >
          <Grid item display="grid">
            <ButtonIconWithTooltip
              tooltipText="Supprimer"
              Icon={
                <ClearRounded
                  fontSize="small"
                  style={{ color: "#FF3100", fontSize: "12px" }}
                />
              }
              onClick={() => {
                deleteClick();
              }}
            />
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                fontSize: "12px",
                color: "#657273",
                textAlign: "left",
              }}
            >
              Condition "composite"
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="grid" style={{ maxWidth: "90px" }}>
        <GenericSelect
          fieldTitle=""
          isMandatory={false}
          options={operatorLogicOptions}
          id="operateur-logique-input"
          placeholder="ET"
          isClearable={false}
          value={
            compositeCondition?.operator
              ? operatorLogicOptions.find(
                  (x) => x?.value === compositeCondition.operator
                )
              : undefined
          }
          onChange={(e) => {
            updateCompositeCondition({
              ...compositeCondition,
              operator: e?.value ? e.value : undefined,
            });
          }}
        />
      </Grid>
      <Grid item display="grid">
        <Grid
          container
          direction="row"
          wrap="nowrap"
          gap="10px"
          alignItems={"center"}
        >
          <Grid item display="grid">
            <span style={{ fontSize: "16px" }}>Ajouter une condition : </span>
          </Grid>
          <Grid item display="grid">
            <AddButton
              text={"simple"}
              displayIconCreate={false}
              displayText={true}
              onClick={() => {
                addSimpleCondition();
              }}
            />
          </Grid>
          <Grid item display="grid">
            <AddButton
              text={"composite"}
              displayIconCreate={false}
              displayText={true}
              onClick={() => {
                addCompositeCondition();
              }}
            />
          </Grid>
          <Grid item display="grid">
            <AddButton
              displayIconCreate={false}
              displayText={true}
              text={"par seuil"}
              onClick={() => {
                addThresholdCondition();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="grid" paddingTop="15px">
        {compositeCondition.conditions.length === 0 ? (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            gap="10px"
            padding="20px"
            borderRadius={"20px"}
            width={"fit-content"}
            border="1px solid rgba(0, 0, 0, 0.3)"
          >
            <Grid item display="grid">
              <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                Aucune condition n'a été ajoutée, commencez par en ajouter une.
              </span>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            gap="10px"
            padding="20px"
            borderRadius={"20px"}
            width={"fit-content"}
            border="1px solid rgba(0, 0, 0, 0.3)"
          >
            {compositeCondition.conditions.map((conditionTmp, index) => {
              if (conditionTmp.type === "simple") {
                const condition: SimpleCondition = conditionTmp;
                return (
                  <Grid item display="grid" key={condition?.id}>
                    <DrawSimpleCondition
                      options={options}
                      simpleCondition={condition}
                      deleteClick={() => {
                        updateCompositeCondition({
                          ...compositeCondition,
                          conditions: compositeCondition.conditions.filter(
                            (y) => y?.id !== condition?.id
                          ),
                        });
                      }}
                      updateSimpleCondition={(updatedCondition) => {
                        updateCompositeCondition({
                          ...compositeCondition,
                          conditions: compositeCondition.conditions.map((y) => {
                            if (y?.id === updatedCondition?.id) {
                              return updatedCondition;
                            }
                            return y;
                          }),
                        });
                      }}
                    />
                  </Grid>
                );
              } else if (conditionTmp.type === "composite") {
                const condition: CompositeCondition = conditionTmp;
                return (
                  <Grid item display="grid" key={condition?.id}>
                    <DrawCompositeCondition
                      options={options}
                      compositeCondition={condition}
                      updateCompositeCondition={(updatedCondition) => {
                        updateCompositeCondition({
                          ...compositeCondition,
                          conditions: compositeCondition.conditions.map((y) => {
                            if (y?.id === updatedCondition?.id) {
                              return updatedCondition;
                            }
                            return y;
                          }),
                        });
                      }}
                      deleteClick={() => {
                        updateCompositeCondition({
                          ...compositeCondition,
                          conditions: compositeCondition.conditions.filter(
                            (y) => y?.id !== condition?.id
                          ),
                        });
                      }}
                    />
                  </Grid>
                );
              } else if (conditionTmp.type === "threshold") {
                const condition: ThresholdCondition = conditionTmp;
                return <Grid item display="grid" key={condition?.id}></Grid>;
              }
              return <></>;
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const DrawSimpleCondition: React.FC<{
  simpleCondition: SimpleCondition;
  updateSimpleCondition: (newSimpleCondition: SimpleCondition) => void;
  deleteClick: () => void;
  options: { id: string; text: string }[];
}> = (props) => {
  const { simpleCondition, updateSimpleCondition, deleteClick, options } =
    props;
  return (
    <Grid container direction="column" wrap="nowrap" width={"fit-content"}>
      <Grid item display="grid" justifySelf={"flex-start"}>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          gap="5px"
          alignItems={"center"}
        >
          <Grid item display="grid">
            <ButtonIconWithTooltip
              tooltipText="Supprimer"
              Icon={
                <ClearRounded
                  fontSize="small"
                  style={{ color: "#FF3100", fontSize: "12px" }}
                />
              }
              onClick={() => {
                deleteClick();
              }}
            />
          </Grid>
          <Grid item display="grid">
            <span
              style={{
                fontSize: "12px",
                color: "#657273",
                textAlign: "left",
              }}
            >
              Condition "simple"
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="grid">
        <Grid
          container
          direction="row"
          wrap="nowrap"
          gap="10px"
          alignItems={"center"}
        >
          <Grid item display="grid">
            <NewGenericSelect
              fixedWidth="200px"
              fieldTitle=""
              isMandatory={false}
              options={options.map((x) => {
                return {
                  label: resizeText(x?.text, 50),
                  value: x?.id,
                };
              })}
              id="input-options-list"
              placeholder="Option"
              isClearable={false}
              value={(() => {
                const founded = options.find(
                  (y) => y?.id == simpleCondition?.option
                );
                if (founded) {
                  return {
                    value: founded?.id,
                    label: founded?.text,
                  };
                }
                return undefined;
              })()}
              onChange={(e) => {
                updateSimpleCondition({
                  ...simpleCondition,
                  option: e?.value ? e.value : undefined,
                });
              }}
            />
          </Grid>
          <Grid item display="grid">
            <NewGenericSelect
              fixedWidth="200px"
              fieldTitle=""
              isMandatory={false}
              options={conditionOperatorOptions}
              maxMenuHeight="135px"
              id="input-operator-options-list"
              placeholder=""
              isClearable={false}
              value={
                simpleCondition?.operator
                  ? conditionOperatorOptions.find(
                      (x) => x?.value === simpleCondition.operator
                    )
                  : undefined
              }
              onChange={(e) => {
                updateSimpleCondition({
                  ...simpleCondition,
                  operator: e?.value ? e.value : undefined,
                });
              }}
            />
          </Grid>
          <Grid item display="grid" style={{ width: "150px" }}>
            <GenericTextInput
              placeholder="Valeur"
              isMandatory={false}
              id="input-value"
              value={simpleCondition?.value}
              onChange={(e) => {
                updateSimpleCondition({
                  ...simpleCondition,
                  value: e?.target?.value ? e.target.value : "",
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// Union de tous les types de conditions
type Condition = SimpleCondition | CompositeCondition | ThresholdCondition;

export type DisplayConditions = {
  operator: LogicalOperator;
  list: Condition[];
};

const NewConditions: React.FC<{
  options: { id: string; text: string }[];
  initialData?: DisplayConditions;
  onDataUpdate?: (newData: DisplayConditions) => void;
}> = (props) => {
  const { options, initialData, onDataUpdate } = props;
  const [conditions, setConditions] = useState<DisplayConditions>(initialData ? initialData : {
    operator: "AND",
    list: [],
  });

  React.useEffect(() => {
    if (initialData) {
      setConditions(initialData);
    }
  }, [initialData]);

  const addSimpleCondition = () => {
    const newCondition: SimpleCondition = {
      operator: "=",
      type: "simple",
      value: "",
      option: undefined,
      id: nanoid(),
    };
    setConditions((x) => {
      const d = { ...x, list: [...x.list, newCondition] };
      onDataUpdate(d);
      return d;
    });
  };

  const addCompositeCondition = () => {
    const newCondition: CompositeCondition = {
      type: "composite",
      operator: "AND",
      conditions: [],
      id: nanoid(),
    };
    setConditions((x) => {
      const d = { ...x, list: [...x.list, newCondition] };
      onDataUpdate(d);
      return d;
    });
  };

  const addThresholdCondition = () => {
    const newCondition: ThresholdCondition = {
      type: "threshold",
      min: 1,
      max: 1,
      conditions: [],
      id: nanoid(),
    };
    setConditions((x) => {
      const d = { ...x, list: [...x.list, newCondition] };
      onDataUpdate(d);
      return d;
    });
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      style={{ fontSize: 16, color: "#0B243C", fontFamily: "Poppins" }}
    >
      <Grid item display="grid" style={{ maxWidth: "90px" }}>
        <GenericSelect
          fieldTitle=""
          isMandatory={false}
          options={operatorLogicOptions}
          id="operateur-logique-input"
          placeholder="ET"
          isClearable={false}
          value={
            conditions?.operator
              ? operatorLogicOptions.find(
                  (x) => x?.value == conditions.operator
                )
              : undefined
          }
          onChange={(e) => {
            setConditions((x) => {
              const d = { ...x, operator: e?.value ? e.value : undefined };
              onDataUpdate(d);
              return d;
            });
          }}
        />
      </Grid>
      <Grid item display="grid" paddingTop="10px">
        <Grid
          container
          direction="row"
          wrap="nowrap"
          gap="10px"
          alignItems={"center"}
        >
          <Grid item display="grid">
            <span>Ajouter une condition : </span>
          </Grid>
          <Grid item display="grid">
            <AddButton
              text={"simple"}
              displayIconCreate={false}
              displayText={true}
              onClick={() => {
                addSimpleCondition();
              }}
            />
          </Grid>
          <Grid item display="grid">
            <AddButton
              text={"composite"}
              displayIconCreate={false}
              displayText={true}
              onClick={() => {
                addCompositeCondition();
              }}
            />
          </Grid>
          <Grid item display="grid">
            <AddButton
              displayIconCreate={false}
              displayText={true}
              text={"par seuil"}
              onClick={() => {
                addThresholdCondition();
              }}
            />
          </Grid>
        </Grid>
        <Grid item display="grid" paddingTop="15px">
          {conditions.list.length === 0 ? (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              gap="10px"
              padding="20px"
              borderRadius={"20px"}
              width={"fit-content"}
              border="1px solid rgba(0, 0, 0, 0.3)"
            >
              <Grid item display="grid">
                <span style={{ fontSize: "14px", fontStyle: "italic" }}>
                  Aucune condition n'a été ajoutée, commencez par en ajouter
                  une.
                </span>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              gap="10px"
              padding="20px"
              borderRadius={"20px"}
              width={"fit-content"}
              border="1px solid rgba(0, 0, 0, 0.3)"
            >
              {conditions.list.map((conditionTmp, index) => {
                if (conditionTmp.type === "simple") {
                  const condition: SimpleCondition = conditionTmp;
                  return (
                    <Grid item display="grid" key={condition?.id}>
                      <DrawSimpleCondition
                        options={options}
                        simpleCondition={condition}
                        deleteClick={() => {
                          setConditions((x) => {
                            const d = {
                              ...x,
                              list: x.list.filter(
                                (y) => y?.id !== condition?.id
                              ),
                            };
                            onDataUpdate(d);
                            return d;
                          });
                        }}
                        updateSimpleCondition={(updatedCondition) => {
                          setConditions((x) => {
                            const d = {
                              ...x,
                              list: x.list.map((y) => {
                                if (y?.id === updatedCondition?.id) {
                                  return updatedCondition;
                                }
                                return y;
                              }),
                            };
                            onDataUpdate(d);
                            return d;
                          });
                        }}
                      />
                    </Grid>
                  );
                } else if (conditionTmp.type === "composite") {
                  const condition: CompositeCondition = conditionTmp;
                  return (
                    <Grid item display="grid" key={condition?.id}>
                      <DrawCompositeCondition
                        options={options}
                        compositeCondition={condition}
                        updateCompositeCondition={(updatedCondition) => {
                          setConditions((x) => {
                            const d = {
                              ...x,
                              list: x.list.map((y) => {
                                if (y?.id === updatedCondition?.id) {
                                  return updatedCondition;
                                }
                                return y;
                              }),
                            };
                            onDataUpdate(d);
                            return d;
                          });
                        }}
                        deleteClick={() => {
                          setConditions((x) => {
                            const d = {
                              ...x,
                              list: x.list.filter(
                                (y) => y?.id !== condition?.id
                              ),
                            };
                            onDataUpdate(d);
                            return d;
                          });
                        }}
                      />
                    </Grid>
                  );
                } else if (conditionTmp.type === "threshold") {
                  const condition: ThresholdCondition = conditionTmp;
                  return (
                    <Grid item display="grid" key={condition?.id}>
                      <DrawSeuilCondition
                        options={options}
                        seuilCondition={condition}
                        updateSeuilCondition={(updatedCondition) => {
                          setConditions((x) => {
                            const d = {
                              ...x,
                              list: x.list.map((y) => {
                                if (y?.id === updatedCondition?.id) {
                                  return updatedCondition;
                                }
                                return y;
                              }),
                            };
                            onDataUpdate(d);
                            return d;
                          });
                        }}
                        deleteClick={() => {
                          setConditions((x) => {
                            const d = {
                              ...x,
                              list: x.list.filter(
                                (y) => y?.id !== condition?.id
                              ),
                            };
                            onDataUpdate(d);
                            return d;
                          });
                        }}
                      />
                    </Grid>
                  );
                }
                return <></>;
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewConditions;

import {
  AnalyticsRounded,
  ClearRounded,
  EditRounded,
  KeyRounded,
  MailRounded,
} from "@mui/icons-material";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import * as React from "react";
import { Tooltip } from "@mui/material";
import CancellablePopup from "../Library/Popups/CancellablePopup";
import makeAPIRequest from "../../utils/makeAPIRequest";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/snackbarReducer";
import FormPopup from "../FormPopup/FormPopup";
import FormPopupHeader from "../FormPopup/FormPopupHeader";
import FullField from "../Fields/FullField";
import TextInput from "../Library/Inputs/TextInput";
import MySelect from "../Library/Select/MySelect";
import getAccountsTypeOptions, {
  getInterfaceOptions,
} from "../../utils/accountsTypeOptions";
import OrangeButton from "../Library/Buttons/OrangeButton/OrangeButton";
import { useOpenSnackbar } from "../../pages/CreateOrEditChatbotLexPage";
import { isPositiveOrNullNumber } from "../../utils/isPositiveNumber";
import OrangeCheckBox from "../Fields/CheckBox/OrangeCheckBox";
import { GenericSelectMulti } from "../../pages/QuestionnairePage";

export function isValidIP(ip: string): boolean {
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipRegex.test(ip);
}

export function isValidDomain(domain: string): boolean {
  const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return domainRegex.test(domain);
}

const convertRole = (number) => {
  if (number == 0) return "Test";
  if (number == 1) return "Classique";
  if (number == 2) return "Premium";
  if (number == 3) return "Expiré";
  if (number == 4) return "Administrateur";
  if (number == 5) return "Limité";
  return "Inconnu";
};

const formatType = (key: string) => {
  if (!key) return "";
  const convert = {
    blocked_by_stop_sms: "Bloqués par STOP",
    total_sended_sms: "Envoyés au total",
    sms_sended_conseil: "Via les conseils",
    sms_sended_conseil_failure: "Via les conseils (Échec)",
    sms_sended_conseil_success: "Via les conseils (Succès)",
    sms_sended_conseil_waiting: "Via les conseils (En attente)",
    sms_sended_chatbot: "Via les questionnaires interactifs",
    sms_sended_form: "Via les questionnaires",
    conseil_created: "Conseils créés",
    display_more_conseils: "Nombre de clics sur afficher plus",
    chatbot_created: "Questionnaires interactifs créés",
    form_created: "Questionnaires créés",
    consommation: "Crédits consommés",
  };
  return convert[key];
};

const formatCategory = (category: string) => {
  if (!category) return "";
  const convert = {
    consommation: "Consommation",
    sms: "SMS",
    conseil: "Conseils",
    chatbot: "Questionnaires interactifs",
    form: "Questionnaires",
  };

  return convert[category];
};

const initStats = (data) => {
  const stats = {
    consommation: {
      consommation: data?.consommation ? data?.consommation : 0,
    },
    sms: {
      total_sended_sms: 0,
      blocked_by_stop_sms: data?.blocked_by_stop_sms
        ? data?.blocked_by_stop_sms
        : 0,
      sms_sended_conseil: data?.sms_sended_conseil
        ? data?.sms_sended_conseil
        : 0,
      sms_sended_conseil_success: data?.sms_sended_conseil_success
        ? data?.sms_sended_conseil_success
        : 0,
      sms_sended_conseil_failure: data?.sms_sended_conseil_failure
        ? data?.sms_sended_conseil_failure
        : 0,
      sms_sended_conseil_waiting: data?.sms_sended_conseil_waiting
        ? data?.sms_sended_conseil_waiting
        : 0,
      sms_sended_chatbot: data?.sms_sended_chatbot
        ? data.sms_sended_chatbot
        : 0,
      sms_sended_form: data?.sms_sended_form ? data.sms_sended_form : 0,
    },
    conseil: {
      conseil_created: data?.conseil_created ? data?.conseil_created : 0,
      display_more_conseils: data?.display_more_conseils
        ? data?.display_more_conseils
        : 0,
    },
    chatbot: {
      chatbot_created: data?.chatbot_created ? data.chatbot_created : 0,
    },
    form: {
      form_created: data?.form_created ? data.form_created : 0,
    },
  };
  (stats["sms"]["sms_sended_form"] += data?.sms_sended_alert_form
    ? data.sms_sended_alert_form
    : 0),
    (stats["sms"]["total_sended_sms"] =
      stats.sms.sms_sended_conseil +
      stats.sms.sms_sended_chatbot +
      stats.sms.sms_sended_form);

  return stats;
};

const UserStatsPopup: React.FC<any> = (props) => {
  const { setUserStatsToDisplay, userStatsToDisplay } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [userStatistics, setUserStatistics] = React.useState(null);

  let date = new Date();
  date = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000 - 24 * 60 * 60 * 1000
  );
  const [startDate, setStartDate] = React.useState(
    date.toISOString().split("T")[0]
  );
  const [startTime, setStartTime] = React.useState(
    date.toISOString().split("T")[1].substring(0, 5)
  );

  date = new Date();
  date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  const [endDate, setEndDate] = React.useState(
    date.toISOString().split("T")[0]
  );
  const [endTime, setEndTime] = React.useState(
    date.toISOString().split("T")[1].substring(0, 5)
  );

  const updateDate = (displayPopup: boolean) => {
    const startTimestamp = Date.parse(startDate + "T" + startTime + ":00");
    const endTimestamp = Date.parse(endDate + "T" + endTime + ":00");
    if (startTimestamp > endTimestamp) {
      dispatch(
        openSnackbar({
          type: "error",
          duration: 3000,
          message: "Les dates sont invalides",
        })
      );
      return;
    }
    if (!displayPopup) setIsLoading(true);
    makeAPIRequest(
      "get",
      `/user/${userStatsToDisplay?.id}/statistic/${startTimestamp}/${endTimestamp}`,
      null,
      "v3"
    )
      .then((res) => {
        setUserStatistics(initStats(res?.data));
        setIsLoading(false);
        if (displayPopup) {
          dispatch(
            openSnackbar({
              message: "Les statistiques ont bien été mises à jour",
              duration: 3000,
              type: "success",
            })
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(
          openSnackbar({
            message:
              "Impossible de récupérer les statistiques de cet utilisateur",
            duration: 3000,
            type: "error",
          })
        );
      });
  };

  React.useEffect(() => {
    updateDate(false);
  }, []);

  return (
    <FormPopup>
      <Grid
        item
        display="grid"
        paddingTop="40px"
        paddingLeft="50px"
        paddingRight="50px"
        paddingBottom="40px"
      >
        <FormPopupHeader
          firstTitle={"Statistique"}
          secondTitle={"d'utilisation"}
          onClose={() => setUserStatsToDisplay(null)}
        />
      </Grid>
      <Grid item display="grid">
        <Grid
          container
          display="flex"
          width="70%"
          justifySelf="center"
          direction="column"
          wrap="nowrap"
          paddingBottom="70px"
        >
          <Grid item display="grid" paddingBottom="15px">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "18px",
                color: "rgb(11, 36, 60)",
              }}
            >
              {userStatsToDisplay?.last_name} {userStatsToDisplay?.first_name}
            </span>
          </Grid>

          <FullField isMandatory={true} title="Période">
            <></>
          </FullField>
          <Grid
            container
            direction="column"
            spacing="10px"
            width="fit-content"
            wrap="nowrap"
          >
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                wrap="nowrap"
                alignItems="center"
                spacing="10px"
              >
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      color: "#0B243C",
                    }}
                  >
                    Du
                  </span>
                </Grid>
                <Grid item display="grid">
                  <input
                    id="statistic-start-date"
                    type="date"
                    style={{
                      minWidth: "100%",
                      border: "1px solid #D3E0E0",
                      background: "#EDF2F280 0% 0% no-repeat padding-box",
                      borderRadius: "10px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      color:
                        startDate && startDate?.length > 0
                          ? "#0B243C"
                          : "#657273",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      height: "42px",
                    }}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Grid>
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      color: "#0B243C",
                    }}
                  >
                    à
                  </span>
                </Grid>

                <Grid item display="grid">
                  <input
                    id="statistic-start-time"
                    type="time"
                    style={{
                      minWidth: "100%",
                      border: "1px solid #D3E0E0",
                      background: "#EDF2F280 0% 0% no-repeat padding-box",
                      borderRadius: "10px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      color:
                        startTime && startTime?.length > 0
                          ? "#0B243C"
                          : "#657273",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      height: "42px",
                    }}
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                wrap="nowrap"
                alignItems="center"
                spacing="10px"
              >
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      color: "#0B243C",
                    }}
                  >
                    Au
                  </span>
                </Grid>
                <Grid item display="grid">
                  <input
                    id="statistic-end-date"
                    type="date"
                    style={{
                      minWidth: "100%",
                      border: "1px solid #D3E0E0",
                      background: "#EDF2F280 0% 0% no-repeat padding-box",
                      borderRadius: "10px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      color:
                        endDate && endDate?.length > 0 ? "#0B243C" : "#657273",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      height: "42px",
                    }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Grid>
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      color: "#0B243C",
                    }}
                  >
                    à
                  </span>
                </Grid>
                <Grid item display="grid">
                  <input
                    id="statistic-end-time"
                    type="time"
                    style={{
                      minWidth: "100%",
                      border: "1px solid #D3E0E0",
                      background: "#EDF2F280 0% 0% no-repeat padding-box",
                      borderRadius: "10px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      color:
                        endTime && endTime?.length > 0 ? "#0B243C" : "#657273",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      height: "42px",
                    }}
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid" justifyContent="flex-end">
              {startDate && endDate && startTime && endTime ? (
                <OrangeButton
                  style={{
                    fontSize: "14px",
                    height: "35px",
                    padding: "2px 10px 2px 10px",
                    borderRadius: "15px",
                  }}
                  animation={false}
                  text="Valider"
                  onClick={() => {
                    updateDate(true);
                  }}
                />
              ) : (
                <OrangeButton
                  style={{
                    fontSize: "14px",
                    height: "35px",
                    padding: "2px 10px 2px 10px",
                    borderRadius: "15px",
                  }}
                  enable={false}
                  animation={false}
                  text="Valider"
                  onClick={() => {
                    return null;
                  }}
                />
              )}
            </Grid>
          </Grid>
          {isLoading ? (
            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingTop="15px"
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <Grid item display="grid" paddingTop="20px">
                <Grid container direction="column" gap="20px" wrap="nowrap">
                  {userStatistics &&
                    Object.keys(userStatistics)?.map((category, index) => {
                      return (
                        <>
                          <Grid item display="grid" marginBottom="-15px">
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                color: "#23595d",
                              }}
                            >
                              {formatCategory(category)}
                            </span>
                          </Grid>
                          <Grid item display="grid">
                            <Grid
                              container
                              direction="column"
                              columnGap="10px"
                              wrap="nowrap"
                            >
                              {Object.keys(userStatistics[category])?.map(
                                (type, index) => {
                                  return (
                                    <Grid item key={type} display="grid">
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "16px",
                                          color: "#0B243C",
                                        }}
                                      >
                                        {formatType(type)} :{" "}
                                        {userStatistics[category][type]}
                                      </span>
                                    </Grid>
                                  );
                                }
                              )}
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </FormPopup>
  );
};

const functionnalitiesOptions = [
  {
    label: "Base conseil",
    value: "base_conseil",
  },
  {
    label: "Base patient",
    value: "patient",
  },
  {
    label: "Chatbot",
    value: "chatbot",
  },
  {
    label: "Questionnaire interfactif",
    value: "interactive_form",
  },
  {
    label: "Questionnaire",
    value: "form",
  },
  {
    label: "Programmation de conseils",
    value: "send_conseil",
  },
];

const UsersListTableContent: React.FC<any> = (props) => {
  const [userStatsToDisplay, setUserStatsToDisplay] = React.useState(null);
  const [userToEdit, setUserToEdit] = React.useState(null);
  const [userToDelete, setUserToDelete] = React.useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { users, setUsers } = props;
  const snackbarUse = useOpenSnackbar();

  const usersOptions =
    users?.length > 0
      ? users?.map((x) => {
          return { label: x?.email, value: x?.id };
        })
      : [];

  return (
    <>
      {userStatsToDisplay && (
        <UserStatsPopup
          userStatsToDisplay={userStatsToDisplay}
          setUserStatsToDisplay={setUserStatsToDisplay}
        />
      )}
      {userToEdit && (
        <FormPopup>
          <Grid
            item
            display="grid"
            paddingTop="40px"
            paddingLeft="50px"
            paddingRight="50px"
            paddingBottom="40px"
          >
            <FormPopupHeader
              firstTitle={"Modifier"}
              secondTitle={"un utilisateur"}
              onClose={() => setUserToEdit(null)}
            />
          </Grid>
          <Grid item display="grid">
            <Grid
              container
              display="flex"
              width="70%"
              justifySelf="center"
              direction="column"
              wrap="nowrap"
              paddingBottom="70px"
            >
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    color: "rgb(11, 36, 60)",
                  }}
                >
                  {userToEdit?.last_name} {userToEdit?.first_name}
                </span>
              </Grid>

              <Grid item display="grid" paddingTop="15px">
                <GenericSelectMulti
                  fieldTitle="Comptes à partager via l'API"
                  id="account-access-via-api"
                  onChange={(e) => {
                    setUserToEdit({
                      ...userToEdit,
                      accessible_accounts:
                        e?.length > 0 ? e.map((opt) => opt.value) : [],
                    });
                  }}
                  options={usersOptions?.filter(
                    (x) => x?.value != userToEdit?.id
                  )}
                  placeholder="Aucun"
                  value={
                    userToEdit?.accessible_accounts?.length > 0
                      ? userToEdit.accessible_accounts
                          .map((x) => {
                            const founded = usersOptions?.find(
                              (y) => y?.value == x
                            );
                            return founded;
                          })
                          .filter((x) => x != undefined)
                      : []
                  }
                  isClearable={true}
                  isMandatory={false}
                />
              </Grid>
              {userToEdit?.accessible_accounts?.length > 0 && (
                <Grid item display="grid" paddingTop="10px">
                  <GenericSelectMulti
                    fieldTitle="Adresse IP ou nom de domaine autorisé"
                    id="restriction-account-access-via-api"
                    onChange={(e) => {
                      if (e?.length > 0) {
                        e.forEach((opt) => {
                          const ipOrDomain = opt?.value;
                          const isValidIPOrDomain =
                            isValidDomain(ipOrDomain) || isValidIP(ipOrDomain);
                          if (!isValidIPOrDomain) {
                            snackbarUse.error(
                              "Adresse IP ou nom de domaine invalide."
                            );
                          }
                        });
                      }
                      setUserToEdit({
                        ...userToEdit,
                        accessible_accounts_ips:
                          e?.length > 0
                            ? e
                                .map((opt) => opt.value)
                                .filter((d) => isValidDomain(d) || isValidIP(d))
                            : [],
                      });
                    }}
                    options={[]}
                    placeholder="Les comptes sont accessibles depuis tout emplacement"
                    value={
                      userToEdit?.accessible_accounts_ips?.length > 0
                        ? userToEdit?.accessible_accounts_ips.map((x) => {
                            return { value: x, label: x };
                          })
                        : []
                    }
                    isClearable={true}
                    isMandatory={false}
                  />
                </Grid>
              )}
              <Grid item display="grid" paddingTop="10px">
                <FullField title="Interface" isMandatory={false}>
                  <MySelect
                    isClearable={false}
                    options={getInterfaceOptions()}
                    onChange={(e) => {
                      setUserToEdit({ ...userToEdit, interface: e?.value });
                    }}
                    value={getInterfaceOptions().find(
                      (e) => e?.value == userToEdit?.interface
                    )}
                  />
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="10px">
                <FullField title="Type de compte" isMandatory={false}>
                  <MySelect
                    isClearable={false}
                    options={getAccountsTypeOptions()}
                    onChange={(e) => {
                      setUserToEdit({ ...userToEdit, account_type: e?.value });
                    }}
                    value={getAccountsTypeOptions().find(
                      (e) => e?.value == userToEdit?.account_type
                    )}
                  />
                </FullField>
              </Grid>
              {userToEdit?.account_type == 5 && (
                <Grid item display="grid" paddingTop="15px">
                  <GenericSelectMulti
                    fieldTitle="Fonctionnalités interdites"
                    id="forbidden_functionnalities"
                    onChange={(e) => {
                      setUserToEdit({
                        ...userToEdit,
                        limited_func:
                          e?.length > 0
                            ? JSON.stringify(e.map((opt) => opt.value))
                            : "[]",
                      });
                    }}
                    options={functionnalitiesOptions}
                    placeholder="Aucune"
                    value={
                      userToEdit?.limited_func?.length > 0
                        ? JSON.parse(userToEdit.limited_func)
                            .map((v) => {
                              const founded = functionnalitiesOptions.find(
                                (y) => y.value == v
                              );
                              return founded;
                            })
                            .filter((z) => z)
                        : []
                    }
                    isClearable={true}
                    isMandatory={false}
                  />
                </Grid>
              )}
              {[0, 1, 3, "0", "1", "3"].includes(userToEdit?.account_type) ? (
                <Grid item display="grid" paddingTop="10px">
                  <FullField title="Jetons" isMandatory={false}>
                    <TextInput
                      value={userToEdit?.tokens}
                      onChange={(e) => {
                        if (e?.target?.value == "") {
                          setUserToEdit({
                            ...userToEdit,
                            tokens: "",
                          });
                        } else if (isPositiveOrNullNumber(e.target.value)) {
                          setUserToEdit({
                            ...userToEdit,
                            tokens: e.target.value,
                          });
                        }
                      }}
                    />
                  </FullField>
                </Grid>
              ) : (
                <Grid item display="grid" paddingTop="10px">
                  <FullField title="Jetons" isMandatory={false}>
                    <TextInput
                      onChange={() => {
                        return null;
                      }}
                      value={"Illimité"}
                    />
                  </FullField>
                </Grid>
              )}
              <Grid item display="grid" paddingTop="10px">
                <FullField
                  title="Abonnement mensuel HT (en €)"
                  isMandatory={false}
                >
                  <TextInput
                    placeholder="0€"
                    value={userToEdit?.abonnement_mensuel}
                    onChange={(e) => {
                      if (e?.target?.value == "") {
                        setUserToEdit({
                          ...userToEdit,
                          abonnement_mensuel: "",
                        });
                      } else if (isPositiveOrNullNumber(e.target.value)) {
                        setUserToEdit({
                          ...userToEdit,
                          abonnement_mensuel: e.target.value,
                        });
                      }
                    }}
                  />
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="10px">
                <FullField title="Limite d'envoi mensuelle" isMandatory={false}>
                  <TextInput
                    onChange={(e) => {
                      if (e?.target?.value == "") {
                        setUserToEdit({
                          ...userToEdit,
                          limitSMS: "",
                        });
                      } else if (isPositiveOrNullNumber(e.target.value)) {
                        setUserToEdit({
                          ...userToEdit,
                          limitSMS: e.target.value,
                        });
                      }
                    }}
                    value={userToEdit?.limitSMS ? userToEdit.limitSMS : ""}
                    placeholder="Non définie"
                  />
                </FullField>
              </Grid>
              {userToEdit?.limitSMS?.length > 0 && (
                <Grid item display="grid" paddingTop="10px">
                  <OrangeCheckBox
                    text="Si limite atteinte, bloquer les envois d'SMS"
                    onClick={() => {
                      setUserToEdit((x) => {
                        return {
                          ...x,
                          blockSMSAfterLimit:
                            x?.blockSMSAfterLimit == "1" ? "0" : "1",
                        };
                      });
                    }}
                    value={userToEdit?.blockSMSAfterLimit == "1" ? true : false}
                  />
                </Grid>
              )}
              {[0, 3, "0", "3"].includes(userToEdit?.account_type) ? (
                <Grid item display="grid" paddingTop="10px">
                  <FullField title="Expiration" isMandatory={false}>
                    <TextInput
                      value={userToEdit?.expiration}
                      onChange={(e) => {
                        setUserToEdit({
                          ...userToEdit,
                          expiration: e.target.value,
                        });
                      }}
                    />
                  </FullField>
                </Grid>
              ) : (
                <Grid item display="grid" paddingTop="10px">
                  <FullField title="Expiration" isMandatory={false}>
                    <TextInput
                      onChange={() => {
                        return null;
                      }}
                      value={"Jamais"}
                    />
                  </FullField>
                </Grid>
              )}
              <Grid item display="grid" paddingTop="10px">
                <FullField title="Nouveau mot de passe" isMandatory={false}>
                  <TextInput
                    onChange={(e) => {
                      setUserToEdit({
                        ...userToEdit,
                        newPassword: e.target.value,
                      });
                    }}
                    value={
                      userToEdit?.newPassword ? userToEdit.newPassword : ""
                    }
                    placeholder="Ne pas changer"
                  />
                </FullField>
              </Grid>
              <Grid item display="grid" paddingTop="10px">
                <FullField title="Email medimail" isMandatory={false}>
                  <TextInput
                    onChange={(e) => {
                      setUserToEdit({
                        ...userToEdit,
                        email_medimail: e.target.value,
                      });
                    }}
                    value={
                      userToEdit?.email_medimail
                        ? userToEdit.email_medimail
                        : ""
                    }
                    placeholder="Entrez le mail pour activer la fonctionnalité"
                  />
                </FullField>
              </Grid>
              {!isSubmitLoading ? (
                <Grid
                  item
                  display="grid"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="30px"
                >
                  <OrangeButton
                    animation={false}
                    enable={!isSubmitLoading}
                    type="submit"
                    text="Mettre à jour"
                    onClick={() => {
                      setIsSubmitLoading(true);
                      const data = {
                        user_id: userToEdit?.id,
                        expiration: userToEdit?.expiration,
                        tokens:
                          userToEdit?.tokens != "" ? userToEdit?.tokens : null,
                        abonnement_mensuel:
                          userToEdit?.abonnement_mensuel != ""
                            ? userToEdit?.abonnement_mensuel
                            : null,
                        account_type: userToEdit?.account_type,
                        interface: userToEdit?.interface,
                        newPassword: userToEdit?.newPassword,
                        email_medimail: userToEdit?.email_medimail,
                        limited_func: userToEdit?.limited_func,
                        limitSMS:
                          userToEdit?.limitSMS != ""
                            ? userToEdit?.limitSMS
                            : null,
                        blockSMSAfterLimit:
                          userToEdit?.blockSMSAfterLimit == "1" ? "1" : "0",
                        accessible_accounts: userToEdit?.accessible_accounts,
                        accessible_accounts_ips:
                          userToEdit?.accessible_accounts_ips,
                      };
                      makeAPIRequest("post", "/user/admin-update", data, "v2")
                        .then((res) => {
                          let usersTmp = [...users];
                          const indexToUpdate = usersTmp?.findIndex(
                            (element) => element?.id === userToEdit?.id
                          );
                          if (indexToUpdate !== -1) {
                            usersTmp[indexToUpdate].expiration =
                              userToEdit?.expiration;
                            usersTmp[indexToUpdate].tokens = data?.tokens;
                            usersTmp[indexToUpdate].abonnement_mensuel =
                              data?.abonnement_mensuel;
                            usersTmp[indexToUpdate].account_type =
                              userToEdit?.account_type;
                            usersTmp[indexToUpdate].interface =
                              userToEdit?.interface;
                            usersTmp[indexToUpdate].email_medimail =
                              userToEdit?.email_medimail;
                            usersTmp[indexToUpdate].limited_func =
                              userToEdit?.limited_func;
                            usersTmp[indexToUpdate].accessible_accounts =
                              userToEdit?.accessible_accounts;
                            usersTmp[indexToUpdate].accessible_accounts_ips =
                              userToEdit?.accessible_accounts_ips;
                            usersTmp[indexToUpdate].limitSMS = data?.limitSMS;
                            usersTmp[indexToUpdate].blockSMSAfterLimit =
                              data?.blockSMSAfterLimit;
                            setUsers(usersTmp);
                          }
                          dispatch(
                            openSnackbar({
                              message: "L'utilisateur a bien été mis à jour",
                              type: "success",
                              duration: 3000,
                            })
                          );
                          setUserToEdit(null);
                        })
                        .catch((err) => {
                          dispatch(
                            openSnackbar({
                              message:
                                "Impossible de mettre à jour cet utilisateur",
                              type: "error",
                              duration: 3000,
                            })
                          );
                        })
                        .finally(() => {
                          setIsSubmitLoading(false);
                        });
                    }}
                  />
                </Grid>
              ) : (
                <Grid item display="grid" paddingTop="30px">
                  <Grid
                    container
                    direction="row"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </FormPopup>
      )}
      {userToDelete && (
        <CancellablePopup
          firstTitle="Supprimer"
          secondTitle="cet utilisateur ?"
          message={`Voulez-vous vraiment supprimer le compte '${userToDelete?.email}' ?`}
          confirmButtonText="Oui"
          cancelButtonText="Non"
          isLoading={isDeleteLoading}
          onCancel={() => {
            setUserToDelete(null);
          }}
          onConfirm={() => {
            setIsDeleteLoading(true);
            makeAPIRequest(
              "post",
              "/user/delete",
              { id: userToDelete?.id },
              "v2"
            )
              .then((res) => {
                let usersTmp = [...users];
                const indexToDelete = usersTmp?.findIndex(
                  (element) => element?.id === userToDelete?.id
                );
                if (indexToDelete !== -1) {
                  delete usersTmp[indexToDelete];
                  usersTmp = usersTmp.filter((element) => element != undefined);
                }
                setUsers(usersTmp);
                dispatch(
                  openSnackbar({
                    message: "L'utilisateur a bien été supprimé",
                    type: "success",
                    duration: 3000,
                  })
                );
                setUserToDelete(null);
                setIsDeleteLoading(false);
              })
              .catch((err) => {
                dispatch(
                  openSnackbar({
                    message: "L'utilisateur n'a pas pu être supprimé",
                    type: "error",
                    duration: 3000,
                  })
                );
                setUserToDelete(null);
                setIsDeleteLoading(false);
              });
          }}
        />
      )}
      {props?.pages?.[props?.actualPage]?.map((user, index) => {
        return (
          <>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {convertRole(user?.account_type)}
            </span>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {user?.email}
            </span>
            <span
              className="table-grid-text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {["2", "4", "5", 2, 4, 5].includes(user?.account_type)
                ? "Illimité"
                : user?.tokens}
            </span>
            <Grid
              container
              className="table-grid-text"
              spacing="20px"
              direction="row"
              wrap="nowrap"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item display="grid">
                {["1", "2", "4", "5", 1, 2, 4, 5].includes(
                  user?.account_type
                ) || !user?.expiration
                  ? "Jamais"
                  : user?.expiration}
              </Grid>
              <Grid item display="grid">
                <Grid container direction="row" gap="10px" maxWidth="110px">
                  <Grid item display="grid">
                    <Tooltip title="Copier le token">
                      <IconButton
                        style={{ margin: "0", padding: "0" }}
                        onClick={() => {
                          makeAPIRequest(
                            "get",
                            `/user/${user?.id}/get_token`,
                            null,
                            "v3"
                          )
                            .then((res) => {
                              console.log(res?.data?.token);
                              navigator.clipboard.writeText(res?.data?.token);
                              snackbarUse.success(
                                `Le token du compte "${user?.email}" a bien été copié.`
                              );
                            })
                            .catch((err) => {
                              snackbarUse.error(
                                "Impossible de récupérer le token de cet utilisateur",
                                err
                              );
                            });
                        }}
                      >
                        <KeyRounded
                          fontSize="small"
                          style={{ color: "#0B243C" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item display="grid">
                    <Tooltip title="Statistiques">
                      <IconButton
                        style={{ margin: "0", padding: "0" }}
                        onClick={() => {
                          setUserStatsToDisplay(user);
                        }}
                      >
                        <AnalyticsRounded
                          fontSize="small"
                          style={{ color: "#0B243C" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item display="grid">
                    <Tooltip title="Modifier">
                      <IconButton
                        style={{ margin: "0", padding: "0" }}
                        onClick={() => setUserToEdit(user)}
                      >
                        <EditRounded
                          fontSize="small"
                          style={{ color: "#0B243C" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item display="grid">
                    <Tooltip title="Supprimer">
                      <IconButton
                        onClick={() => setUserToDelete(user)}
                        style={{
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        <ClearRounded
                          fontSize="small"
                          style={{ color: "#FF3100" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {user?.email_medimail?.length > 0 && (
                    <Grid item display="grid">
                      <Tooltip title="Cet utilisateur a accès aux fonctionnalités Medimail">
                        <MailRounded
                          fontSize="small"
                          style={{ color: "#0B243C" }}
                        />
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      })}
    </>
  );
};

export default UsersListTableContent;

import { Grid } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import EventEmitter from "../../../services/event";
import useAppSelector from "../../../redux/useAppSelector";

const handlePatientBase = () => {
  EventEmitter.emit("PatientBase", {
    page: "PatientCard",
  });
};

const MonPatientHeader = () => {
  const user = useAppSelector((x) => x?.userReducer?.user);

  return (
    <Grid
      container
      direction="row"
      justifyContent={
        user?.interface && !["8742"].includes(user?.interface)
          ? "space-around"
          : "flex-start"
      }
      spacing="5px"
    >
      <Grid item display="grid">
        <span
          style={{
            textAlign: "left",
            font: "normal normal bold 26px/32px Poppins",
            color: "#5BB9C1",
          }}
        >
          Mon{" "}
          <span
            style={{
              textAlign: "left",
              font: "normal normal bold 26px/32px Poppins",
              color: "#0B243C",
            }}
          >
            Patient
          </span>
        </span>
      </Grid>
      {user?.interface && !["8742"].includes(user?.interface) && (
        <Grid item display="grid">
          <Link to={"/patient_list_v2"} style={{ textDecoration: "none" }}>
            <button
              className="modal-toggle"
              onClick={handlePatientBase}
              style={{
                borderRadius: "10px",
                background: "#0B243C 0% 0% no-repeat padding-box",
                fontWeight: 500,
                fontSize: "13px",
                fontFamily: "Poppins",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "35px",
                maxWidth: "254px",
                width: "254px",
                color: "#FFFFFF",
                letterSpacing: "0px",
              }}
            >
              <span>Gérer la base de données patients</span>
            </button>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

export default MonPatientHeader;

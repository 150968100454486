import authServices from "../services/auth.services";

export interface IUser {
  user_id?: string;
  email: string,
  phone: string,
  user_login: string,
  id: number,
  civility: string,
  profession_id: string,
  speciality_main_id: string,
  first_name: string,
  email_medimail?: string,
  last_name: string,
  speciality_others_ids: string,
  nni_id: any,
  nni_type: any,
  account_type: string,
  interface?: string,
  tokens: string,
  abonnement_mensuel: string,
  expiration: string,
  expeditor_name: string,
  blockSMSAfterLimit?: "0" | "1",
  stop_sms_active: "0" | "1",
  receive_sms_failure_active: "0" | "1",
  nickname: string,
  mail_preference?: string,
  limited_func: string,
}

class UserClass {
  user?: IUser;

  constructor() {
    this.user = null;
  }

  public init = async () => {
    this.user = await this.getFullObject();
  }

  public refresh = async () => {
    this.user = await this.getFullObject();
  }

  public getFullObject = async () => {
    const newUser: IUser = {
      id: null,
      email: null,
      phone: null,
      user_login: null,
      civility: null,
      profession_id: null,
      speciality_main_id: null,
      first_name: null,
      last_name: null,
      speciality_others_ids: null,
      nni_id: null,
      nni_type: null,
      tokens: null,
      abonnement_mensuel: null,
      account_type: null,
      expiration: null,
      expeditor_name: null,
      stop_sms_active: null,
      receive_sms_failure_active: null,
      nickname: null,
      limited_func: null,
    };
    const res = await authServices.get_profile(1);
    if (!res?.data?.extra_user_meta[0]?.id)
      return (null);
    newUser.id = res?.data?.extra_user_meta[0]?.id;
    newUser.email = res?.data?.extra_user_meta[0]?.email;
    newUser.phone = res?.data?.extra_user_meta[0]?.phone;
    newUser.user_login = res?.data?.extra_user_meta[0]?.user_login;
    newUser.civility = res?.data?.extra_user_meta[0]?.civility;
    newUser.profession_id = res?.data?.extra_user_meta[0]?.profession_id;
    newUser.speciality_main_id = res?.data?.extra_user_meta[0]?.speciality_main_id;
    newUser.last_name = res?.data?.extra_user_meta[0]?.last_name;
    newUser.first_name = res?.data?.extra_user_meta[0]?.first_name;
    newUser.speciality_others_ids = res?.data?.extra_user_meta[0]?.speciality_others_ids;
    newUser.nni_id = res?.data?.extra_user_meta[0]?.nni_id;
    newUser.nni_type = res?.data?.extra_user_meta[0]?.nni_type;
    newUser.tokens = res?.data?.extra_user_meta[0]?.tokens;
    newUser.abonnement_mensuel = res?.data?.extra_user_meta[0]?.abonnement_mensuel;
    newUser.expiration = res?.data?.extra_user_meta[0]?.expiration;
    newUser.account_type = res?.data?.extra_user_meta[0]?.account_type;
    newUser.interface = res?.data?.extra_user_meta[0]?.interface;
    newUser.expeditor_name = res?.data?.extra_user_meta[0]?.expeditor_name;
    newUser.email_medimail = res?.data?.extra_user_meta[0]?.email_medimail;
    newUser.mail_preference = res?.data?.extra_user_meta[0]?.mail_preference;
    newUser.stop_sms_active = res?.data?.extra_user_meta[0]?.stop_sms_active;
    newUser.limited_func = res?.data?.extra_user_meta[0]?.limited_func;
    newUser.receive_sms_failure_active = res?.data?.extra_user_meta[0]?.receive_sms_failure_active;
    newUser.nickname = res?.data?.user_meta?.nickname?.[0];
    return (newUser);
  };

  public isConnected() {
    if (this.user)
      return (true);
    return (false);
  };

  public getPhone = () => {
    return (this.user?.phone);
  };

  public getLastName = () => {
    return (this.user?.last_name);
  };

  public getFirstName = () => {
    return (this.user?.first_name);
  };

  public getLimitedFunc = () => {
    return (this.user?.limited_func);
  };

  public getMailPreference = () => {
    return (this.user?.mail_preference);
  };

  public getMainSpeciality = () => {
    return (this.user?.speciality_main_id);
  };

  public getOthersSpecialities = () => {
    return (this.user?.speciality_others_ids);
  };

  public getNniType = () => {
    return (this.user?.nni_type);
  };

  public getNniId = () => {
    return (this.user?.nni_id);
  };

  public getProfession = () => {
    return (this.user?.profession_id);
  };

  public getCivility = () => {
    return (this.user?.civility);
  };

  public getNickname = () => {
    return (this.user?.nickname);
  };

  public getEmail = () => {
    return (this.user?.email);
  }

  public getLogin = () => {
    return (this.user?.user_login);
  }

  public getId = () => {
    return (this.user?.id);
  };

  public getTokens = () => {
    return (this.user?.tokens);
  };

  public getAccountType = () => {
    return (this.user?.account_type);
  };

  public getInterface = () => {
    return (this.user?.interface);
  };

  public getExpeditorName = () => {
    return (this.user?.expeditor_name);
  };

  public getMailMedimail = () => {
    return (this.user?.email_medimail);
  };

  public getStopSMSActive = () => {
    return (this.user?.stop_sms_active);
  };

  public getReceiveSMSFailureActive = () => {
    return (this.user?.receive_sms_failure_active);
  };
  
}

export default UserClass;
import { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Link, Redirect, useHistory } from "react-router-dom";
import logo from "../assets/logo.png";
import Icon_Logout from "../assets/Icone_Deconnection.png";
import Groupe766 from "../assets/Groupe766.png";
import IUsersData from "../types/Users";
import EventBus from "../common/EventBus";
import AuthService from "../services/auth.services";
import EventEmitter from "../services/event";
import * as React from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";
import AccountMenu from "./AccountMenu";
import SelectAccount from "./SelectAccount";
import useAppSelector from "../redux/useAppSelector";
import {
  NotificationItem,
  NotificationReducerState,
  NotificationsParametersLanguage,
  openNotifications,
  updateParameters,
} from "../redux/new/notificationsReducer";
import {
  ArrowBackOutlined,
  Notifications,
  NotificationsNone,
  SettingsOutlined,
} from "@mui/icons-material";
import useAppDispatch from "../redux/useAppDispatch";
import OrangeCheckBox from "./Fields/CheckBox/OrangeCheckBox";
import { useOpenSnackbar } from "../pages/CreateOrEditChatbotLexPage";
import FullField from "./Fields/FullField";

const ITEM_INCREMENTATION = 10;

const ProfileButton: React.FC<any> = (props) => {
  const addOpacity =
    window.location.href.endsWith("profile") ||
    window.location.href.endsWith("scheduled_conseils") ||
    window.location.href.endsWith("send_messages") ||
    window.location.href.endsWith("questionnaires");

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item display="grid">
        <Link to={"/profile"} className="nav-link">
          <Grid container direction="row" spacing="10px" wrap="nowrap">
            <Grid
              item
              display="grid"
              justifyContent="center"
              alignItems="center"
            >
              <img
                style={{
                  width: "22px",
                  height: "22px",
                  opacity: addOpacity ? 0.4 : 1,
                }}
                src={Groupe766}
                alt=""
              />
            </Grid>
            <Grid
              item
              display="grid"
              justifyContent="center"
              alignItems="center"
            >
              <span
                style={{
                  font: "normal normal normal 18px Poppins",
                  color: "#0B243C",
                  textAlign: "center",
                  opacity: addOpacity ? 0.4 : 1,
                }}
              >
                {props.currentUser && props.currentUser.user_display_name}
              </span>
            </Grid>
          </Grid>
        </Link>
      </Grid>
      <Grid item display="grid">
        <SelectAccount />
      </Grid>
    </Grid>
  );
};

const DisconnectButton: React.FC<any> = (props) => {
  return (
    <a href="/login" className="nav-link" onClick={props.doLogout}>
      <Grid
        container
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
        spacing="10px"
      >
        <Grid item display="grid" justifyContent="center" alignItems="center">
          <img
            style={{ width: "24px", height: "22px" }}
            src={Icon_Logout}
            alt=""
          />
        </Grid>
        <Grid item display="grid" justifyContent="center" alignItems="center">
          <span
            style={{
              font: "normal normal normal 18px Poppins",
              color: "#0B243C",
              textAlign: "center",
            }}
          >
            Se déconnecter
          </span>
        </Grid>
      </Grid>
    </a>
  );
};

export const getActualTimeNumber = () => {
  return +new Date(
    new Date().toLocaleString("en-EN", { timeZone: "Europe/Paris" })
  );
};

export const getActualTimeFromYYYYMMDD = (date?: string) => {
  if (date) {
    return new Date(
      new Date(date).toLocaleString("en-EN", { timeZone: "Europe/Paris" })
    );
  }
  return new Date(
    new Date().toLocaleString("en-EN", { timeZone: "Europe/Paris" })
  );
};

export const getActualTime = (timeStamp?: number) => {
  if (timeStamp) {
    return new Date(
      new Date(timeStamp).toLocaleString("en-EN", { timeZone: "Europe/Paris" })
    );
  }
  return new Date(
    new Date().toLocaleString("en-EN", { timeZone: "Europe/Paris" })
  );
};

export const useOnEndScroll = (ref: any, onScrollEnd, isActive = true) => {
  const [canScrollAgain, setCanScrollAgain] = React.useState<boolean>(true);
  React.useEffect(() => {
    const div = ref.current;
    if (div) {
      handleScroll();
      div.addEventListener("scroll", handleScroll);
      return () => div.removeEventListener("scroll", handleScroll);
    }
  }, [isActive, canScrollAgain]);

  const handleScroll = () => {
    const div = ref.current;
    if (div) {
      const scrollBottom = div.scrollHeight - div.scrollTop - div.clientHeight;
      if (scrollBottom <= 1 && canScrollAgain) {
        setCanScrollAgain(false);
        setTimeout(() => {
          onScrollEnd();
        }, 200);
        setTimeout(() => {
          setCanScrollAgain(true);
        }, 2000);
      }
    }
  };
};

export const useOnOutsideClick = (ref: any, onClickOutside: () => void) => {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const NotificationList: React.FC<{
  languages: NotificationsParametersLanguage;
  closePopup: () => void;
  notifications: NotificationItem[];
  lastOpen: number;
}> = (props) => {
  const { notifications, lastOpen, languages } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [actualTimestamp, setActualTimestamp] = React.useState(
    getActualTimeNumber()
  );
  React.useEffect(() => {
    const interval = setInterval(() => {
      setActualTimestamp(getActualTimeNumber());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {notifications.map((notif, index) => {
        const isNew = notif.date > lastOpen;
        return (
          <Grid
            item
            display="grid"
            className="notification-item"
            padding="10px"
            key={index}
            borderBottom={
              index !== notifications.length - 1 || isNew
                ? "1px solid #0B243C"
                : undefined
            }
            style={{
              cursor: notif.onClickRedirect ? "pointer" : "default",
            }}
            onClick={
              notif.onClickRedirect
                ? () => {
                    dispatch(openNotifications());
                    props.closePopup();
                    history.push(notif.onClickRedirect);
                  }
                : () => {
                    return;
                  }
            }
          >
            <Grid container direction="row" wrap="nowrap" gap="15px">
              {isNew && (
                <Grid
                  item
                  display="grid"
                  alignSelf="center"
                  justifySelf="center"
                >
                  <div
                    style={{
                      width: "8px",
                      height: "8px",
                      borderRadius: "8px",
                      backgroundColor: "#ffa500",
                    }}
                  />
                </Grid>
              )}
              <Grid item xs display="grid">
                <Grid container direction="column" wrap="nowrap">
                  {languages.notificationsParametersLanguage.find(
                    (x) => x.value == notif.type
                  )?.label && (
                    <Grid item display="grid" paddingBottom="5px">
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {
                          languages.notificationsParametersLanguage.find(
                            (x) => x.value == notif.type
                          ).label
                        }
                      </span>
                    </Grid>
                  )}
                  <Grid item display="grid">
                    <span
                      style={{
                        textAlign: "start",
                        fontSize: "14px",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {notif.content.replaceAll("\\n", "\n")}
                    </span>
                  </Grid>
                  <Grid
                    item
                    display="grid"
                    justifySelf="flex-end"
                    marginTop="10px"
                  >
                    <span
                      style={{
                        textAlign: "end",
                        fontSize: "12px",
                      }}
                    >
                      {getTextElapsedFromTimestamp(notif.date, actualTimestamp)}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export const getTextElapsedFromTimestamp = (
  timestamp: number,
  actualTimestamp?: number
): string => {
  const actualTimestampInfo = actualTimestamp
    ? actualTimestamp
    : getActualTimeNumber();
  const elapsedTime = actualTimestampInfo - timestamp;

  if (elapsedTime >= 31536000000) {
    // 1 an en millisecondes
    const years = Math.floor(elapsedTime / 31536000000);
    return `Il y a ${years} an${years > 1 ? "s" : ""}`;
  } else if (elapsedTime >= 2592000000) {
    // 1 mois en millisecondes
    const months = Math.floor(elapsedTime / 2592000000);
    return `Il y a ${months} mois`;
  } else if (elapsedTime >= 86400000) {
    // 1 jour en millisecondes
    const days = Math.floor(elapsedTime / 86400000);
    return `Il y a ${days} jour${days > 1 ? "s" : ""}`;
  } else if (elapsedTime >= 3600000) {
    // 1 heure en millisecondes
    const hours = Math.floor(elapsedTime / 3600000);
    return `Il y a ${hours} heure${hours > 1 ? "s" : ""}`;
  } else if (elapsedTime >= 60000) {
    // 1 minute en millisecondes
    const minutes = Math.floor(elapsedTime / 60000);
    return `Il y a ${minutes} minute${minutes > 1 ? "s" : ""}`;
  } else {
    // moins d'une minute
    const seconds = Math.floor(elapsedTime / 1000);
    return `Il y a ${seconds} seconde${seconds > 1 ? "s" : ""}`;
  }
  return "";
};

const NotificationButton: React.FC<{
  notificationsReducer: NotificationReducerState;
}> = (props) => {
  const { notificationsReducer } = props;
  const [isParametersOpen, setIsParametersOpen] =
    React.useState<boolean>(false);
  const [newNotifications, setNewNotifications] = React.useState<
    NotificationItem[]
  >([]);
  const [isOpenNotifications, setIsOpenNotifications] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    if (!notificationsReducer.notifications.list) {
      return;
    }
    const { list, lastOpen } = notificationsReducer.notifications;
    const newNotificationsTmp = list.filter((x) => x.date > lastOpen);
    setNewNotifications(newNotificationsTmp);
  }, [notificationsReducer.notifications]);
  const ref = React.useRef(null);
  const listNotificationRef = React.useRef(null);
  const [maxDisplay, setMaxDisplay] = React.useState(ITEM_INCREMENTATION);

  const closePopup = () => {
    setIsOpenNotifications(false);
    setMaxDisplay(ITEM_INCREMENTATION);
    setIsParametersOpen(false);
  };

  useOnEndScroll(
    listNotificationRef,
    () => {
      setMaxDisplay((x) => x + ITEM_INCREMENTATION);
    },
    isOpenNotifications
  );
  useOnOutsideClick(ref, () => {
    setIsOpenNotifications((x) => {
      if (x === true) {
        dispatch(openNotifications());
        setMaxDisplay(ITEM_INCREMENTATION);
        setIsParametersOpen(false);
      }
      return false;
    });
  });
  const dispatch = useAppDispatch();
  const accesses = useAppSelector((state) => state.authorizationsReducer);
  const openSnackbar = useOpenSnackbar();
  const forbidden = [];
  if (!accesses.forms) {
    forbidden.push("new_form_response");
  }
  if (!accesses.chatbots_lex) {
    forbidden.push("chatbot_conversation_cancelled");
    forbidden.push("chatbot_conversation_ended");
  }
  if (!accesses.chatbots) {
    forbidden.push("questionnaire_interactif_ended");
  }

  return (
    <>
      <div
        ref={ref}
        style={{
          position: "relative",
          zIndex: 500,
        }}
      >
        <Tooltip title="Notifications">
          <IconButton
            style={{
              margin: "0",
              padding: "0",
            }}
            onClick={() => {
              setIsOpenNotifications((x) => {
                if (x === true) {
                  dispatch(openNotifications());
                  setMaxDisplay(ITEM_INCREMENTATION);
                  setIsParametersOpen(false);
                }
                return !x;
              });
            }}
          >
            {newNotifications.length > 0 ? (
              <>
                <div
                  style={{
                    fontSize: "10px",
                    minWidth: "24px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    backgroundColor: "#ffa500",
                    position: "absolute",
                    right: -10,
                    top: -3,
                    color: "#0b243c",
                  }}
                >
                  <span
                    style={{
                      paddingLeft: "2px",
                      paddingRight: "2px",
                    }}
                  >
                    {newNotifications.length}
                  </span>
                </div>
                <Notifications style={{ color: "#0B243C", fontSize: "26px" }} />
              </>
            ) : (
              <NotificationsNone
                style={{ color: "#0B243C", fontSize: "26px" }}
              />
            )}
          </IconButton>
        </Tooltip>
        {isOpenNotifications && (
          <div
            style={{
              backgroundColor: "white",
              width: "300px",
              borderRadius: "16px",
              position: "absolute",
              display: "block",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              right: "2px",
              top: "30px",
              zIndex: 100,
            }}
          >
            <Grid
              style={{
                fontFamily: "Poppins",
                fontSize: "16px",
                color: "#0B243C",
              }}
              container
              direction="column"
              wrap="nowrap"
            >
              <Grid
                item
                display="grid"
                borderBottom="1px solid #0B243C"
                padding="10px"
              >
                <Grid
                  container
                  direction="row"
                  gap="10px"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  {isParametersOpen && (
                    <Grid item display="grid">
                      <Tooltip title="Retour">
                        <IconButton
                          style={{ margin: "0", padding: "0" }}
                          onClick={() => {
                            setIsParametersOpen(false);
                          }}
                        >
                          <ArrowBackOutlined
                            fontSize="small"
                            style={{ color: "#0B243C" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid item display="grid">
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgb(91, 185, 193)",
                      }}
                    >
                      Mes{" "}
                      <span style={{ color: "#0b243c" }}>
                        {isParametersOpen ? "Paramètres" : "Notifications"}
                      </span>
                    </span>
                  </Grid>
                  {!isParametersOpen && (
                    <Grid item display="grid">
                      <Tooltip title="Paramètres">
                        <IconButton
                          style={{ margin: "0", padding: "0" }}
                          onClick={() => {
                            setIsParametersOpen(true);
                          }}
                        >
                          <SettingsOutlined
                            fontSize="small"
                            style={{ color: "#0B243C" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {!isParametersOpen &&
                (notificationsReducer.notifications.list.length > 0 ? (
                  <Grid
                    item
                    display="grid"
                    ref={listNotificationRef}
                    style={{
                      minHeight: "200px",
                      overflowX: "scroll",
                      maxHeight: "400px",
                    }}
                  >
                    <Grid container direction="column" wrap="nowrap">
                      <>
                        <NotificationList
                          languages={notificationsReducer.languages}
                          closePopup={closePopup}
                          notifications={notificationsReducer.notifications.list.slice(
                            0,
                            maxDisplay
                          )}
                          lastOpen={notificationsReducer.notifications.lastOpen}
                        />
                      </>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item display="grid" padding="10px">
                    <span
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {"Vous n'avez reçu aucune notification."}
                    </span>
                  </Grid>
                ))}
              {isParametersOpen &&
                notificationsReducer?.languages?.notificationsParametersLanguage
                  ?.length > 0 && (
                  <Grid item display="grid" padding="20px">
                    <FullField
                      isMandatory={false}
                      title="Notifications actives"
                    >
                      <Grid
                        container
                        direction="column"
                        wrap="nowrap"
                        gap="10px"
                      >
                        {notificationsReducer.languages.notificationsParametersLanguage.map(
                          (paramL, index) => {
                            // Liste des notifications interdites (car pas d'autorisation)
                            if (forbidden.includes(paramL.value)) {
                              return <></>;
                            }
                            // Liste des notifications pris en charge pour le moment
                            if (
                              ![
                                "new_form_response",
                                "incident_sms_send",
                                "end_pack_sms",
                                "chatbot_conversation_cancelled",
                                "questionnaire_interactif_ended",
                                "chatbot_conversation_ended",
                                "code_qr",
                              ].includes(paramL.value)
                            ) {
                              return <></>;
                            }
                            return (
                              <Grid item display="grid" key={index}>
                                <OrangeCheckBox
                                  textStyle={{
                                    fontSize: "14px",
                                  }}
                                  value={
                                    notificationsReducer.notifications
                                      ?.parameters?.[paramL.value]
                                  }
                                  text={paramL.label}
                                  onClick={() => {
                                    if (!paramL.value) {
                                      return;
                                    }
                                    dispatch(
                                      updateParameters({
                                        [paramL.value]: notificationsReducer
                                          .notifications?.parameters?.[
                                          paramL.value
                                        ]
                                          ? false
                                          : true,
                                      })
                                    )
                                      .unwrap()
                                      .then(() => {
                                        return;
                                      })
                                      .catch((err) => {
                                        openSnackbar.error(
                                          "Impossible de mettre à jour ce paramètre.",
                                          err
                                        );
                                      });
                                  }}
                                />
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    </FullField>
                  </Grid>
                )}
              <Grid item display="grid" style={{ height: "10px" }}></Grid>
            </Grid>
          </div>
        )}
      </div>
    </>
  );
};

const MenuButtons: React.FC<any> = (props) => {
  const { currentUser, doLogout } = props;
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );
  const notificationsReducer = useAppSelector(
    (state) => state.notificationsReducer
  );
  const user = useAppSelector((x) => x?.userReducer?.user);

  return (
    <Grid
      container
      display="flex"
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      {windowWidth > 1100 ? (
        <Grid container direction="row" columnSpacing="25px">
          {notificationsReducer?.languages &&
            notificationsReducer?.notifications && (
              <Grid item display="grid">
                <NotificationButton
                  notificationsReducer={notificationsReducer}
                />
              </Grid>
            )}
          {user?.interface && !["8742"].includes(user?.interface) && (
            <Grid item display="grid">
              <ProfileButton currentUser={currentUser} />
            </Grid>
          )}
          <Grid item display="grid">
            <DisconnectButton doLogout={doLogout} />
          </Grid>
        </Grid>
      ) : (
        <Grid item display="grid">
          <Grid container direction="row" gap="10px" alignItems="center">
            {notificationsReducer?.languages &&
              notificationsReducer?.notifications && (
                <Grid item display="grid">
                  <NotificationButton
                    notificationsReducer={notificationsReducer}
                  />
                </Grid>
              )}
            <Grid item display="grid">
              <AccountMenu />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const BrandCertiPair = () => {
  const windowWidth = useAppSelector(
    (state) => state.windowReducer.windowWidth
  );

  return (
    <Navbar.Brand href="#home?f=conseils">
      <Grid container direction="row" display="flex" spacing="18px">
        <Grid item display="grid">
          <img
            src={logo}
            className="d-inline-block align-top brand-logo"
            alt="CertiPair"
          />
        </Grid>
        {windowWidth > 700 && (
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap">
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    color: "#0B243C",
                  }}
                >
                  Accompagnez vos patients avec
                </span>
              </Grid>
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    color: "#0B243C",
                  }}
                >
                  des messages pertinents et personnalisés
                </span>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Navbar.Brand>
  );
};

type Props = {
  isLoggedIn?: boolean;
};

type State = {
  redirect: string | null;
  userReady: boolean;
  currentUser: IUsersData | undefined;
  isLoggedIn?: boolean;
};

const NewVersion: React.FC = () => {
  const token = localStorage.getItem("token");
  const user = useAppSelector((x) => x?.userReducer?.user);
  const canGoNewVersion =
    user?.interface && !["8742"].includes(user?.interface);
  return (
    <>
      {canGoNewVersion && (
        <div
          style={{
            position: "absolute",
            top: "42px",
            left: "-20px",
          }}
        >
          <span
            className="opacity-animation normal-text"
            style={{
              fontSize: "12px",
              color: "rgb(141, 152, 153)",
              margin: 0,
              padding: "10px 20px 5px",
            }}
            onClick={() => {
              window.location.replace(
                `https://app2.certipair.fr/#/envoyer?token=${token}`
              );
            }}
          >
            {`<< Accéder à la nouvelle interface`}
          </span>
        </div>
      )}
    </>
  );
};

export default class NavbarComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
    this.setState({ currentUser: user, userReady: true });

    const onUserChange = () => {
      this.setState({
        currentUser: JSON.parse(localStorage.getItem("user")),
      });
    };

    const listenerFilled = EventEmitter.addListener("userChange", onUserChange);

    EventBus.on("logout", this.logOut);
    return () => {
      listenerFilled.remove();
    };
  }

  componentWillUnmount() {
    EventBus.remove("logout", this.logOut);
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentUser: undefined,
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const { currentUser } = this.state;
    return (
      <Grid container display="flex" direction="column" wrap="nowrap">
        {this.state.userReady ? (
          (this.props as any)?.isLoggedIn === false ? (
            <Grid
              container
              direction="row"
              display="flex"
              spacing="15px"
              className="color-nav shadow-none container-nav"
            >
              <Grid item display="grid">
                <Grid container direction="column" wrap="nowrap">
                  <Grid item display="grid">
                    <BrandCertiPair />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              spacing="15px"
              display="flex"
              className="color-nav shadow-none container-nav"
            >
              <Grid item display="grid">
                <div style={{ position: "relative" }}>
                  <BrandCertiPair />
                  <NewVersion />
                </div>
              </Grid>
              <Grid item display="grid">
                <MenuButtons doLogout={this.logOut} currentUser={currentUser} />
              </Grid>
            </Grid>
          )
        ) : null}
      </Grid>
    );
  }
}

import FullField from "../../../Fields/FullField";
import * as React from "react";
import HandleErrors from "../../../Library/Errors/HandleErrors";
import {
  MedimailSearchType,
  getMedimailSearch,
} from "../../SendResponseMedimailPopup";
import makeAPIRequest from "../../../../utils/makeAPIRequest";
import TextInput from "../../../Library/Inputs/TextInput";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { Dropdown } from "react-bootstrap";
import { removeParenthesis } from "../../../MesConseilsBox/TabsContainer/AdminTab/AdminTabContent";


export const GenericHourInput = (props: {
  onChange: (e: any) => void;
  value: string | undefined;
  placeholder?: string;
  fieldTitle: string;
  disableError?: boolean;
  id: string;
  isMandatory?: boolean;
  hideAsterics?: boolean;
}) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  return (
    <FullField
      isQuestionnaire={true}
      title={props?.fieldTitle}
      isMandatory={props?.isMandatory}
      hideAsterics={props?.hideAsterics ? true : false}
    >
      <HandleErrors
        isError={isError && !props?.disableError}
        isMandatory={props?.isMandatory}
        errorMessage={errorMessage}
      >
        <input
          id={props?.id}
          //isError={isError && !disableError}
          type="time"
          style={{
            maxWidth: "200px",
            border: "1px solid #D3E0E0",
            background: "#EDF2F280 0% 0% no-repeat padding-box",
            borderRadius: "10px",
            paddingRight: "20px",
            paddingLeft: "20px",
            color:
              props?.value && props?.value?.length > 0 ? "#0B243C" : "#657273",
            fontFamily: "Poppins",
            fontSize: "16px",
            height: "42px",
          }}
          value={props?.value ? props?.value : ""}
          onChange={props?.onChange}
          onFocus={(e) => {
            if (!props?.isMandatory) return;
            if (isError && !props?.disableError) {
              setErrorMessage("");
              setIsError(false);
            }
          }}
          onBlur={(e) => {
            if (!props?.isMandatory) return;
            if (!e.target.value) {
              if (!isError && !props?.disableError) {
                setErrorMessage("Ce champ est obligatoire.");
                setIsError(true);
              }
            } else {
              if (isError && !props?.disableError) {
                setErrorMessage("");
                setIsError(false);
              }
            }
          }}
        />
      </HandleErrors>
    </FullField>
  );
};

export const GenericDateInput = (props: {
  onChange: (e: any) => void;
  value: string | undefined;
  placeholder?: string;
  fieldTitle: string;
  disableError?: boolean;
  id: string;
  isMandatory?: boolean;
  hideAsterics?: boolean;
}) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  return (
    <FullField
      isQuestionnaire={true}
      title={props?.fieldTitle}
      isMandatory={props?.isMandatory}
      hideAsterics={props?.hideAsterics ? true : false}
    >
      <HandleErrors
        isError={isError && !props?.disableError}
        isMandatory={props?.isMandatory}
        errorMessage={errorMessage}
      >
        <input
          id={props?.id}
          //isError={isError && !disableError}
          type="date"
          style={{
            maxWidth: "200px",
            border: "1px solid #D3E0E0",
            background: "#EDF2F280 0% 0% no-repeat padding-box",
            borderRadius: "10px",
            paddingRight: "20px",
            paddingLeft: "20px",
            color:
              props?.value && props?.value?.length > 0 ? "#0B243C" : "#657273",
            fontFamily: "Poppins",
            fontSize: "16px",
            height: "42px",
          }}
          value={props?.value ? props?.value : ""}
          onChange={props?.onChange}
          onFocus={(e) => {
            if (!props?.isMandatory) return;
            if (isError && !props?.disableError) {
              setErrorMessage("");
              setIsError(false);
            }
          }}
          onBlur={(e) => {
            if (!props?.isMandatory) return;
            if (!e.target.value) {
              if (!isError && !props?.disableError) {
                setErrorMessage("Ce champ est obligatoire.");
                setIsError(true);
              }
            } else {
              if (isError && !props?.disableError) {
                setErrorMessage("");
                setIsError(false);
              }
            }
          }}
        />
      </HandleErrors>
    </FullField>
  );
};

export const DisplayAnnuaireMSSante: React.FC<any> = (props) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const {
    field,
    sectionIndex,
    fieldIndex,
    fieldRowIndex,
    questionnaireData,
    disableError,
    setQuestionnaireData,
  } = props;

  const setMSSanteNomPrenom = (value: string) => {
    const questionnaireDataTmp = { ...questionnaireData };

    questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
      fieldIndex
    ].value = value;
    setQuestionnaireData(questionnaireDataTmp);
  };
  const MSSanteNomPrenom =
    removeParenthesis(questionnaireData.sections[sectionIndex].fields[fieldRowIndex][
      fieldIndex
    ].value);

  //const [MSSanteNomPrenom, setMSSanteNomPrenom] = React.useState<string>("");
  const [firstDataLoaded, setFirstDataLoaded] = React.useState<boolean>(false);
  const [destOptions, setDestOptions] = React.useState<MedimailSearchType[]>(
    []
  );
  const [destLoading, setDestLoading] = React.useState<boolean>(false);
  const [searchList, setSearchStack] = React.useState<string[]>([]);
  const [lastChangeTime, setLastChangeTime] = React.useState<number>(
    Date.now()
  );
  const [timeNow, setTimeNow] = React.useState(Date.now());
  const initialSearchInput = {
    nom: "", //
    adresse: "",
    departement: "",
    mail: "",
    postalCode: "", //
    prenom: "", //
    profession: "", //
    region: "",
    rpps: "", //
    specialite: "", //
    tel: "",
    ville: "", //
  };
  const [searchInputs, setSearchInputs] =
    React.useState<MedimailSearchType>(initialSearchInput);

  React.useEffect(() => {
    const search = getMedimailSearch(searchInputs);
    if (!search || search === searchList?.[searchList.length - 1]) {
      setDestLoading(false);
      setDestOptions([]);
      return;
    }
    setDestLoading(true);
    setLastChangeTime(Date.now());
    setSearchStack((x) => {
      return [...x, search];
    });
  }, [searchInputs]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeNow(Date.now());
    }, 500);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    if (lastChangeTime + 1000 > timeNow) {
      return;
    }
    if (!searchList || searchList.length === 0) {
      setDestLoading(false);
      setDestOptions([]);
      return;
    }
    const search = searchList[searchList.length - 1];
    if (search === searchList?.[searchList.length - 2]) {
      return;
    }
    if (search) {
      setSearchStack((x) => {
        return [...x, search];
      });
      makeAPIRequest(
        "post",
        "/medimail/annuaire/public_search",
        {
          search: search,
        },
        "v3"
      )
        .then((res) => {
          setFirstDataLoaded(true);
          const data = res?.data;
          if (Array.isArray(data) && data.length > 0) {
            setDestOptions(data);
          } else {
            setDestOptions([]);
          }
        })
        .catch((err) => {
          console.log(err);
          return;
        })
        .finally(() => {
          setDestLoading(false);
        });
    } else {
      setDestOptions([]);
      setDestLoading(false);
    }
  }, [searchList, lastChangeTime, timeNow]);

  return (
    <FullField
      isQuestionnaire={true}
      title={field?.title}
      isMandatory={field?.isMandatory}
      hideAsterics={field?.hideAsterics ? true : false}
    >
      <HandleErrors
        isError={isError && !disableError}
        isMandatory={field?.isMandatory}
        errorMessage={errorMessage}
      >
        <Grid container direction="column" wrap="nowrap">
          <Grid item display="grid">
            <TextInput
              id={"field" + field?.id}
              placeholder="Nom et prénom"
              isError={isError && !disableError}
              onChange={(e) => {
                const value = e?.target?.value ? e.target.value : "";
                let override: { nom?: string; prenom?: string } = {};
                const splited = value?.split(" ");
                if (splited?.[0]) {
                  override.nom = splited[0];
                }
                if (splited?.[1]) {
                  override.prenom = splited[1];
                }
                setMSSanteNomPrenom(value);
                setSearchInputs((x) => {
                  return { ...x, ...override };
                });
              }}
              onFocus={(e) => {
                if (!field?.isMandatory) return;
                if (isError && !disableError) {
                  setErrorMessage("");
                  setIsError(false);
                }
              }}
              onBlur={(e) => {
                if (!field?.isMandatory) return;
                if (!e.target.value) {
                  if (!isError && !disableError) {
                    setErrorMessage("Ce champ est obligatoire.");
                    setIsError(true);
                  }
                } else {
                  if (isError && !disableError) {
                    setErrorMessage("");
                    setIsError(false);
                  }
                }
              }}
              value={field?.value ? removeParenthesis(field?.value) : ""}
            />
          </Grid>
          {destOptions?.length === 0 &&
            firstDataLoaded &&
            !destLoading &&
            MSSanteNomPrenom && (
              <Grid
                item
                display="grid"
                marginBottom={"-10px"}
                marginTop={"5px"}
              >
                <span
                  style={{
                    textAlign: "end",
                    color: "#FF3100",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                  }}
                >
                  {`Médecin introuvable.`}
                </span>
              </Grid>
            )}
        </Grid>
        {(destOptions?.length > 0 || destLoading) && (
          <Grid item display="grid" paddingRight="20px">
            <Dropdown.Menu
              show
              style={{
                borderRadius: "0px 10px 10px 10px",
                zIndex: 100,
                padding: "0",
                marginTop: "-5px",
                marginLeft: "2px",
                overflow: "none",
                maxWidth: "400px",
              }}
            >
              <ul
                className="drowDownSearch"
                style={{
                  zIndex: 100,
                  maxHeight: "300px",
                  overflow: "auto",
                  fontFamily: "Poppins",
                  color: "#0B243C",
                }}
              >
                {!destLoading && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      padding: "5px 10px 5px 5px",
                    }}
                  >
                    <Tooltip title="Fermer">
                      <IconButton
                        style={{ margin: "0", padding: "0" }}
                        onClick={() => {
                          setDestLoading(false);
                          setDestOptions([]);
                          setSearchStack([]);
                          setFirstDataLoaded(false);
                          setSearchInputs(initialSearchInput);
                          
                          ("");
                          setIsError(true);
                          setErrorMessage("Ce champ est obligatoire.")
                        }}
                      >
                        <CloseRounded
                          fontSize="small"
                          style={{ color: "#0B243C" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                {destLoading && (
                  <li
                    className="PatientUnit"
                    style={{
                      height: "100%",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "10px",
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      style={{
                        color: "#657273",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </li>
                )}
                {!destLoading &&
                  destOptions
                    .filter((x) => x.mail && x.nom && x.prenom)
                    .map((destOption, index) => {
                      return (
                        <li
                          key={index}
                          className="PatientUnit"
                          style={{
                            height: "100%",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            flexDirection: "column",
                            display: "flex",
                          }}
                          onClick={() => {
                            console.log(
                              destOption.nom + " " + destOption.prenom
                            );
                            setMSSanteNomPrenom(
                              destOption.nom + " " + destOption.prenom + " (" + destOption.mail + ")"
                            );
                            setDestLoading(false);
                            setIsError(false);
                            setDestOptions([]);
                            setSearchStack([]);
                            setFirstDataLoaded(false);
                            setSearchInputs(initialSearchInput);
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            gap="5px"
                          >
                            <Grid item display="grid">
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  color: "#0B243C",
                                  wordBreak: "break-all",
                                  fontWeight: 500,
                                }}
                              >
                                {destOption.nom} {destOption.prenom}
                              </span>
                            </Grid>
                            <Grid item display="grid">
                              <span
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  color: "#0B243C",
                                  wordBreak: "break-all",
                                }}
                              >
                                {destOption.mail}
                              </span>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    })}
              </ul>
            </Dropdown.Menu>
          </Grid>
        )}
      </HandleErrors>
    </FullField>
  );
};

const DisplayDate: React.FC<any> = (props) => {
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const {
    field,
    sectionIndex,
    fieldIndex,
    fieldRowIndex,
    questionnaireData,
    disableError,
    setQuestionnaireData,
  } = props;

  return (
    <FullField
      isQuestionnaire={true}
      title={field?.title}
      isMandatory={field?.isMandatory}
      hideAsterics={field?.hideAsterics ? true : false}
    >
      <HandleErrors
        isError={isError && !disableError}
        isMandatory={field?.isMandatory}
        errorMessage={errorMessage}
      >
        <input
          id={"field" + field?.id}
          //isError={isError && !disableError}
          type="date"
          style={{
            maxWidth: "200px",
            border: "1px solid #D3E0E0",
            background: "#EDF2F280 0% 0% no-repeat padding-box",
            borderRadius: "10px",
            paddingRight: "20px",
            paddingLeft: "20px",
            color:
              field?.value && field?.value?.length > 0 ? "#0B243C" : "#657273",
            fontFamily: "Poppins",
            fontSize: "16px",
            height: "42px",
          }}
          value={field?.value ? field?.value : ""}
          onChange={(e) => {
            const questionnaireDataTmp = { ...questionnaireData };

            questionnaireDataTmp.sections[sectionIndex].fields[fieldRowIndex][
              fieldIndex
            ].value = e.target.value;
            setQuestionnaireData(questionnaireDataTmp);
          }}
          onFocus={(e) => {
            if (!field?.isMandatory) return;
            if (isError && !disableError) {
              setErrorMessage("");
              setIsError(false);
            }
          }}
          onBlur={(e) => {
            if (!field?.isMandatory) return;
            if (!e.target.value) {
              if (!isError && !disableError) {
                setErrorMessage("Ce champ est obligatoire.");
                setIsError(true);
              }
            } else {
              if (isError && !disableError) {
                setErrorMessage("");
                setIsError(false);
              }
            }
          }}
        />
      </HandleErrors>
    </FullField>
  );
};

export default DisplayDate;
